@import 'globalStyles';

.Sandbox {
  padding: 1rem;
  background: #eee;


  .blocks > div {
    background: #fff;
    margin-bottom: 5rem;
    padding: 1rem;
    border: dotted 1px #222;
  }
}

@import 'globalStyles';