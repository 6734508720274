@import 'globalStyles';

.Switch {
  @include buttonsGroup;

  &__option {
    &._active {
      background: rgba(#000, 0.2);

      &:hover {
        background: rgba(#000, 0.2);
      }
    }
  }
}