@import "globalStyles";

.TextArea {
  &:not(.Mui-focused) {
    border: solid 1px $separatorColor;
    border-radius: 0;
  }

  &.Mui-focused {
    border: solid 1px rgb(255,221,0);
    border-radius: 0;
  }
}