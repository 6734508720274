@import 'globalStyles';
html {
  overflow: hidden !important;
}
.main {
  &.Page {
    @media (max-width: 1199px) {
      padding-top: 117px;
    }
  }
}
.Page {
  height: 100%;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;

  @include for-phone-only {
    height: calc(100dvh - 117px) !important;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    @include for-phone-only {
      margin-top: 16px;
      flex-direction: column-reverse;
    }

  }

  &__wrapper {
    position: relative;
    padding: $pagePadding;
    max-width: 57.5rem;
    box-sizing: border-box;

    @include for-phone-only {
      padding: 16px;
    }

    &._fullWidth {
      max-width: unset;
      width: 100%;
      max-width: 100%;
    }
  }

  @media(min-width: 1200px) {
    width: calc(100vw - 290px);

    &._isMenuHidden {
      width: calc(100vw - 47px);
    }
  }

  &__title {
    font-size: 1.875rem;
    margin-bottom: 0.9375rem;
    line-height: 2.5rem;

    @include for-phone-only {
      margin-top: 16px;
    }
  }

  &__actions {
    @include buttonsGroup;
    position: absolute;
    top: 1.2rem;
    right: 1rem;

    a {
      display: inline-block;
      margin-left: 1rem;
    }
  }

  &__back {
    @include clickable;
    position: relative;
    z-index: 2;
    margin: 1rem 2rem;
    display: inline-block;
    text-decoration: none;
    font-size: 1.2rem;
    border-bottom: solid 1px rgba(0,0,0,0.3);

    .icon {
      transform: rotate(90deg);
      vertical-align: top;
      margin: 0.2rem -0.2rem 0 -0.4rem;
      opacity: 0.5;
    }
  }

  &__content {
    height: 100%;
  }
}
