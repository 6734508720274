@import 'globalStyles';

// html, body, #main {
//   height: 100%;
// }

// #main {
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

.CaptchaMobileModal {
  margin: 0 16px;
  padding: 32px 24px;

  border-radius: 8px;
  border: 1px solid var(--tertiary-200, #F3F7FF);
  background: var(--White, #FFF);
  box-shadow: 0px 6px 56px 0px rgba(56, 120, 255, 0.10);
}

.CaptchaForm {
  display: inline-block;
  width: 100%;

  &__title {
    color: $secondary400;
    font-size: 24px;
    line-height: 34px;
    font-weight: 600;
    margin-bottom: 32px;

    @include for-phone-only {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 24px;
    }
  }

  &__captcha-container {
    display: flex;

    img {
      width: 180px;
    }
  }

  &__input-container {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
  }

  &__validate-button {
    width: 100%;
    height: 42px;
    border-radius: 6px;

    margin-top: 24px;
  }

  &__captcha-input {
    margin: 0;
  }

  &__other-code-label {
    border: none;
    background: transparent;
    cursor: pointer;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin-left: 20px;

    svg {
      fill: none;
      stroke: $secondary400;
      stroke-width: 2;

      &:hover {
        stroke: #3878FF;
      }
    }
  }
}
