@import 'globalStyles';

.PhotoPreview {
  position: relative;
  background: #eee;
  margin: -1px;
  width: 62px;
  height: 62px;
  border-radius: 8px;
  margin-right: 8px;
  flex-shrink: 0;

  &._clickable {
    @include clickable;
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
    background-size: cover;
    background-position: center;
  }

  &__status {
    @include centerPosition;
    z-index: 10;
    color: red;
  }

  &._isLoaded {
    .PhotoPreview__content {
      opacity: 1;
      pointer-events: all;
    }
  }

  img {
    @include centerPosition;
    height: 100%;
    width: auto;
    opacity: 0;
  }

  &__remove {
    @include clickable;
    position: absolute;
    bottom: 2px;
    right: 2px;
    background: #fff;
    padding: 3px;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border-radius: 50%;

    stroke-width: 2px;
    stroke: #212121;
    fill: #212121;

    & svg {
      width: 16px;
      height: 16px;

      &:hover {
        fill: #fff;
      }
    }

    &:hover {
      background-color: $mainBg !important;

      & svg {
        fill: #000;
        stroke:#000;
      }
    }
  }
}

.PhotoPreviewNonSquare {
  &__content {
    background-size: contain;
    background-repeat: no-repeat;
  }

  &._isLoaded {
    .PhotoPreviewNonSquare {
      opacity: 1;
      pointer-events: all;
    }
  }
}
