@import 'globalStyles';

.IssueLocation {
  &__part {
    white-space: nowrap;
    display: inline-block;
  }

  .icon {
    vertical-align: middle;
    margin-left: -0.25rem;
    transform: translateY(-0.1rem);
    opacity: 0.35;
  }
}