@import 'globalStyles';

.BookingItemForm {
  & .NoResults {
    margin-top: 1rem;
  }

  @media (max-width: 600px) {
    & .FormButtons > button {
      display: block;
      margin: 0 auto;
    }
  }
}
