.TreeViewItem {
  &__item {
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    padding-left: 4px;
  }

  &__label {
    color: var(--primary-60, #212121);
    font-size: 15px;
    text-overflow: ellipsis;
    font-family: robotomedium, sans-serif;
    width: 100%;
  }

  &__collapseIcon {
    transform: rotate(-90deg);
  }
}
