@import 'globalStyles';

$dragOverColor: #CEF2E9;
$dragBetweenColor: #7CE8CF;
$selectedColor: rgba(0,0,0,0.2);

@mixin bgTransition {
  transition: background 0.5s ease 0.05s;
}

.TreeView {
  background: #fff;
  cursor: pointer;
  position: relative;
  border: solid $separatorColor 1px;

  &__node {
    overflow: visible;
    @include bgTransition;

    &._dragging {
      opacity: 0.3;
    }

    &._dragging .TreeView__node-children {
      pointer-events: none;
    }

    &._selected {
      position: relative;
      z-index: 2;
      background: rgba(0,0,0,0.05);

      & > .TreeView__node-title {
        background: $selectedColor;
      }
    }

    &._leaf > .TreeView__node-title .TreeView__expand-node {
      opacity: 0;

      &._isDisplayIcon {
        opacity: 1;
      }
    }

    @for $i from 0 through 10 {
      &._level_#{$i} {
        .TreeView__node-title {
          padding-left: 12px + (12px* $i);
        }

        .TreeView__node-content {
          padding-left: 2rem + (1.5rem * $i);
        }
      }
    }
  }

  &__node-content {
    padding-top: 0.1rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
    position: relative;
    background: rgba(0,0,0,0.05);
    border-bottom: solid 1px $selectedColor;
    z-index: 3;

    &:empty {
      display: none;
    }
  }

  &__node-children {
    padding: 0.5rem 0;

    ._root & {
      padding: 0;
    }
  }

  &__node-title {
    position: relative;
    padding: 0.5rem;
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
    @include bgTransition;

    &:hover {
      background: rgba(0,0,0,0.05);
    }
  }

  &__node-name {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    }

  &__node-description {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: #919499;
  }

  &__expand-node {
    vertical-align: top;
    padding: 0.125rem;
    transform: rotate(-90deg);
    flex-shrink: 0;

    ._expanded >  .TreeView__node-title & {
      transform: rotate(0);
    }
  }
  
  &__expand-node._isDisplayIcon {
    vertical-align: top;
    padding: 0.125rem;
    transform: none !important;
    flex-shrink: 0;
    margin-right: 8px;

    ._expanded>.TreeView__node-title {
        transform: none !important;
    }
}

  &__dropBefore,
  &__dropAfter,
  &__dropAtRootEnd {
    $height: 0.5rem;
    height: $height;
    position: relative;
    z-index: 3;
    margin-bottom: calc(#{$height} / -2);
    @include bgTransition;

    &._dragOver {
      background: $dragBetweenColor;
    }
  }

  &__dragPreview {
    background: #fff;
    border-radius: 2rem;
    border: solid 1px #eee;
    display: inline-block;
    padding: 0.25rem 1rem;
  }

  .Loader {
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    bottom: 0.5rem;
    margin-top: 0.1rem;
    pointer-events: none;
    right: 0.5rem;
    stroke: rgba(0,0,0,0.3);
    stroke-width: 8px;
  }
}
