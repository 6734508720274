@import 'globalStyles';

.Table {
  $cellPadding: 0.5rem 1rem;

  background: #fff;
  border: 1px solid #CCC;
  overflow-x: unset;
  display: table;

  @include for-phone-only {
    display: block;
  }

  &__wrapper {
    position: relative;
  }

  &__header {
    line-height: 1.2em;

    &._sticky {
      display: none;
      background: #fff;
      z-index: 2;
      top: 0;

      @media (max-width: 1199px) {
        top: 64px;
      }

      @include for-phone-only {
        display: none;
      }

      .Table__headerRow {
        display: flex;
        width: 100%;
      }

      .Table__headerCell {
        box-sizing: border-box;
        display: block;
        white-space: normal;
      }
    }
  }

  &__headerCell {
    @include capitalize;
    text-align: center;
    padding: $cellPadding;
    position: relative;
    line-height: 1rem;

    &._dragging {
      background: rgba(#000, 0.2);
    }

    &._sortable {
      cursor: pointer;

      & svg {
        opacity: 0.3;
      }
    }
  }

  &__headerDropTarget {
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    right: -1px;

    &._isOver {
      border-right: solid 7px rgba(#000, 0.3);
      left: 50%;
      right: -4px;
    }

    &._dropBefore {
      right: 50%;
      left: 0;

      &._isOver {
        border-right: 0;
        border-left: solid 7px rgba(#000, 0.3);
        left: -4px;
        right: 50%;
      }
    }
  }

  &__body {
    color: opacify($textColor, 0.8);
  }

  &__cell {
    padding: $cellPadding;

    &._number {
      text-align: right;
    }

    &._location {
      width: 15rem;
    }

    &._centered {
      text-align: center;
    }
  }

  @include for-phone-only {
    font-size: 0.9rem;
    &__header {
      display: none;
    }

    &__cell {
      padding: 0.2rem 0;
      position: relative;
      white-space: nowrap;

      & > * {
        white-space: normal;
        display: inline-block;
        width: 50%;
      }

      &:before {
        content: attr(data-column);
        display: inline-block;
        vertical-align: top;
        width: 40%;
        white-space: normal;

        &:first-letter {
          text-transform: uppercase;
        }
      }
    }

    &__row {
      padding: 0.5rem 1rem;
      border-bottom: solid 1px #eee;
      //width: calc(100vw-1rem);
    }
  }

  @include for-tablet-portrait-up {
    font-size: 0.8rem;

    &__header {
      display: table-row-group;
    }

    &__headerRow {
      display: table-row;

      &._sticky {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 9;
        background: #FFF;
      }
    }

    &__headerCell {
      display: table-cell;
      border-bottom: solid 1px #aaa;
      border-right: solid 1px #f2f2f2;

      &._isSortedBy {
        background-color: rgba(100,100,200,0.05);

        & svg {
          opacity: 1;
        }
      }
    }

    &__body {
      display: table-row-group;
    }

    &__row {
      display: table-row;
    }

    &__cell {
      display: table-cell;
      border-bottom: solid 1px #eee;
    }
  }

  .NestedPath {
    .NestedPath__item {
      position: relative;
      min-width: 8rem;
      margin-bottom: 0.2rem;

      &:before {
        content: '› ';
        position: absolute;
        left: -0.6rem;
      }

      @for $i from 0 through 10 {
        &:nth-child(n+#{$i}) {
          margin-left: $i * 0.6rem;
        }
      }
    }
  }
}

