@import 'globalStyles';

.Expander {
  &__handler {
    @include clickable;
    opacity: 0.6;
    font-size: 0.8rem;
    position: relative;

    .icon {
      @include rightPosition;
    }
  }

  &__content {
    padding: 0.5rem 0;
  }

  &._expanded {
    .icon {
      transform: rotate(180deg) translateY(50%);
    }
  }
}