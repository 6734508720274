.TenantDocsPage {

    &__list-item {
        padding-left: 2rem;
        position: relative;

        &::before {
            content: "";
            width: 20px;
            height: 10px;
            background-color: #761b14;

            display: block;
            position: absolute;
            top: 8px;
            left: 0px;
        }
    }

    &__link {
        text-decoration: none;

        color: #761b14;
        font-size: 18px;
        line-height: 27px;

        &:hover {
            color: #000;
        }
    }
}