@import 'globalStyles';

.FileInput {
  display: flex;
  align-items: stretch;
  position: relative;

  &__input {
    width: 0.1px;
    height: 0.1px;
    position: absolute;
    opacity: 0;
    overflow: hidden;
    z-index: -1;
  }

  &__button {
    position: relative;
    border-radius: 3px;

    .icon {
      @include leftPosition(0.5rem);
      margin-top: 1px;
    }

    &:hover {
      cursor: pointer;
      background: rgba(0, 0, 0, 0.04);
    }
  }

  &__files {
    box-shadow: inset 0 0 0 1px rgba(5, 5, 5, 0.2);
    margin-left: -1px;
    flex-grow: 1;
    flex-shrink: 0;
    padding: 0.5rem 1rem;
    opacity: 0.7;
    border-radius: 0 3px 3px 0;
  }

  &__clear {
    @include rightPosition(0.5rem);
    @include clickable;
    z-index: 2;
  }

  &._showFiles {
    .FileInput__button {
      border-radius: 3px 0 0 3px;
    }
  }

  &._showIcon {
    padding-left: 2rem;
  }

  &._disabled {
    @include disabled;
  }
}
