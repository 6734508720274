@import 'globalStyles';

.TgPopoverContent {
    display: flex;
    gap: 24px;    
    
    @include for-phone-only {
        flex-direction: column;
        align-items: center;
    }

  &__qrCode {
    height: 112px;
  }

  &__textBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    align-items: flex-start;

    @include for-phone-only {
        align-items: center;
        text-align: center;
    }
  }

  &__text {
    padding-right: 10px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    color: #212121;
  }

  &__button {
    font-size: 16px;
    font-weight: 500;
    padding: 10px 16px;
  }
}