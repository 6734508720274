@import 'globalStyles';
@import '../variables.scss';

.SubMenu {
  overflow: hidden;
  box-sizing: border-box;
  max-width: 100vw;

  &__handler {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__items {
    @media (max-width: 1199px) {
      div {
        padding-left: 24px;
      }
    }
  }

  @include for-tablets-and-phones {
    margin: 0.8rem -1rem 1rem;
    margin-bottom: 3rem;
  }

  .MenuItem__link {
    padding-right: 0;
  }
}
