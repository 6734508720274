.NumberInput {
  display: inline-block;

  &._wide {
    width: 100%;

    input {
      width: 100%;
    }
  }
}