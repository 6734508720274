@import 'globalStyles';

.EmptyFeed {
  text-align: center;
  padding: 3.125rem 0;

  .icon {
    width: 17.5rem;
    height: 17.5rem;
    color: transparent;
  }

  &__text {
    opacity: 0.5;
    display: inline-block;
    max-width: 20rem;
    color: var(--primary-60, #212121)
  }
}