@import 'globalStyles';

.inputContainer {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  @include for-phone-only {
    align-items: flex-start;
  }
}

.ConfirmCodeInput {
  display: flex;
  gap: 12px;

  & > input {
    border: solid 1px #a8adb7;
    font-size: 20px;
    color: #525461;
    text-align: center;
    box-sizing: border-box;
    border-radius: 6px;
    -webkit-appearance: initial;
  }

  & > input:focus {
    outline: none;
    border: 1px solid #006fff;
    caret-color: #006fff;
  }
}
