@import 'globalStyles';

.Modal._confirmCode {

  & .content {
    width: 25rem;
  }
}

.ConfirmCodeModal {


  &__description {
    margin-left: 1rem;
    font-size: 0.8rem;
    text-align: center;
  }

  .FormField {
    margin: 1rem auto;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;

    &__input {
      display: flex;

      input {
        width: 5rem;
      }


    }
  }

  .FormButtons {
    text-align: center;
  }
}