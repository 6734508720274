@import 'globalStyles';

.EntryPage {
  background: #fff;
  height: 100dvh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  display: grid;
  grid-template-columns: 400px 1fr;
  grid-template-areas: 'banner entry';

  @include for-tablets-and-phones {
    grid-template-areas: 'entry';
    grid-template-columns: 1fr;

    margin-top: -68px;
  }

  &__sidebannerFooter {
    @include for-tablets-and-phones {
      display: none;
    }
  }

  &__footer {
    width: calc(400px - 64px);

    @include for-phone-only {
      width: auto;
    }
  }

  &__footerText {
    display: flex;
    gap: 12px;
    font-family: IBM Plex Sans, serif;
    font-size: 13px;
    padding: 12px 0;
    border-bottom: 1px solid #D5E0FF;
    margin-bottom: 12px;
  }

  &__subtext {
    color: #39506C;
    font-family: IBM Plex Sans, serif;
    font-size: 13px;
    text-decoration: none;

    &:not(:last-child):after {
      content:"\00B7";
      margin: 8px;
    }
  }
}
