@import 'globalStyles';

html {
  font-size: 1rem;

  @include for-tablet-portrait-up {
    font-size: 1rem;
  }

  @include for-tablet-landscape-up {
    font-size: 1rem;
  }

  @include for-big-desktop-up {
    font-size: 1rem;
  }
}

body {
  line-height: 1.5rem;

  font-size: 0.875rem;

  @include for-tablet-portrait-up {
    font-size: 0.875rem;
  }

  @include for-tablet-landscape-up {
    font-size: 0.875rem;
  }

  @include for-big-desktop-up {
    font-size: 1rem;
  }
}
