@import 'globalStyles';

.InventoryReportTable {
  &__cell {
    white-space: nowrap;
  }

  &__change {
    vertical-align: super;
    font-size: smaller;

    &.positive {
      color: $greenColor;
    }

    &.negative {
      color: $redColor;
    }
  }

  &__name-column {
    width: 200px;
  }
}