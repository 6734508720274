@import 'globalStyles';

.Notification {
  padding: 20px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);

  &__content {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.5rem;
    width: 100%;
    font-family: $regularFont;
    cursor: pointer;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
    position: relative;
    margin-bottom: 6px;
    /*max-height: 48px;
    height: auto;
    overflow: hidden;
    text-overflow: ellipsis;*/
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__indicator {
    position: absolute;
    left: -11px;
  }

  &__message {
    font-weight: 400;
    text-align: left;
    margin-bottom: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__button {
    color: #212121;
    padding: 7px 10px;
    border-radius: 4px;
    border: 1px solid var(--tertiary-10-gray-2, #E1E2E2) !important;
    background: var(--secondary-30, #FFF) !important;

    &:hover {
      background: var(--tertiary-10-gray-1, #F5F5F5) !important;
    }
  }

  &__date {
    font-weight: 400;
    opacity: 0.4;
    text-wrap: nowrap;


    & .Date__time {
      opacity: 1;
    }
  }
}

@include for-phone-only {
  .Notification {
    padding: 16px;
    border: none;
    border-top: 1px solid #E1E2E2;
    border-radius: 0;
    box-shadow: none;

    & .PageActions {
      position: absolute;
      top: 3rem;
      right: 2rem;
    }


    &__content {
      flex-direction: column-reverse;
    }

    &__header {
      display: flex;
      flex-direction: column-reverse;
    }

    &__date {
      margin-bottom: 8px;
    }
  }
}
