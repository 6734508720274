@import 'globalStyles';
@import 'elements/input';

.SimpleSelect {
  position: relative;

  &__clear {
    position: absolute;
    right: 0;
    top: -28px;
  }

  &__wrapper {
    position: relative;
  }

  &__icon {
    width: 2.5rem;
    border-radius: 50%;
    margin-right: 1rem;
  }

  &__input {
    @include input;
    @include clickable;
    padding-right: 2.2rem;
    overflow-wrap: anywhere;
    min-height: 38px;
  }

  &__value {
    display: flex;
  }

  &__dropdown {
    position: absolute;
    background: #fff;
    z-index: $dropdownLayer;
    border: solid 1px #ccc;
    box-shadow: 0 2px 3px 3px rgba(0, 0, 0, 0.1);
    border-radius: 0.2rem;
    transform: translateY(-0.2rem);
    margin-top: 8px;
  }

  &__searchWrapper {
    position: relative;
    border-bottom: solid 1px #EEE;
  }

  &__search {
    border: 0 !important;
  }

  &__searchIcon {
    @include clickable;
    @include rightPosition(0.5rem);
    opacity: 0.3;
  }

  &__options {
    max-height: 20rem;
    overflow: auto;
  }

  &__arrow {
    @include rightPosition(0.5rem);

    &._isFocused {
      transform: translateY(-50%) rotate(-180deg);
    }
  }

  &__option {
    @include clickable;
    padding: 0.5rem 1rem;
    font-size: 15px;

    &._isActive {
      background: #f8f8f8;
    }
  }

  &__option_content {
    display: flex;
    align-items: center;
    position: relative;

    &._noWrap {
      white-space: nowrap;
    }

    &::after {
      position: absolute;
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      right: 0;
    }

    &._isBusy {
      &::after {
        background-color: $yellowColor;
      }
    }

    &._isAvailable {
      &::after {
        background-color: $greenColor;
      }
    }

    &._isVeryBusy {
      &::after {
        background-color: $redColor;
      }
    }
  }

  .Label {
    vertical-align: top;
    line-height: inherit;
  }

  &__placeholder {
    opacity: 0.3;
  }

  &__closeDropdown {
    @include clickable;
    font-size: 0.8rem;
    background: #eee;
    text-align: center;
    padding: 0.2rem;
    position: relative;
    height: 1rem;
    border-bottom: solid 1px #ccc;

    .icon {
      width: 0.8rem;
      height: 0.8rem;
      @include rightPosition(0.5rem);
    }
  }

  &__closeDropdownText {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.4rem;
  }

  &__noResults {
    text-align: center;
    padding: 0.5rem;
    opacity: 0.3;
    background: #ddd;
  }

  &__valuesWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
  }

  &__loader {
    text-align: center;
    padding: 0.5rem 0;

    .Loader {
      width: 20px;
      height: 20px;
      stroke: rgba(0, 0, 0, 0.3);
      stroke-width: 10px;
    }
  }

  &__avatar {
    margin-right: 12px;
  }

  &__label {

    &._favorite {
      font-weight: 600;
    }
  }
}

.Select__body-scroll-block {
  overflow: hidden !important;

  .Modal {
    overflow: hidden !important;
  }
}
