@import 'globalStyles';

.LocationTreeSelect {
  &._withMy {
    .TreeViewSelect {
      &__dropdown {
      }

      &__filter {
        background: #fff;
        border-bottom: solid 1px $separatorColor;

        .Search input {
          border-bottom-color: #eee;
        }

        .Checkbox {
          margin: 0.5rem;
        }
      }
    }
  }

  &._onlyMy {
    .TreeViewSelect {
      &__dropdown {
        padding-top: 0;
      }

      &__filterExtra {
        display: none;
      }

      .Checkbox {
        display: none;
      }

      ._leaf .Checkbox {
        display: inline-block;
      }
    }
  }
}
