.privacy-footer {
    margin-bottom: 16px;

    color: #39506C;
    font-family: IBM Plex Sans, serif;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;

    & a {
        color: #3878FF;
        text-decoration: none;
    }

    & svg {
        fill: #3878FF;
    }

}