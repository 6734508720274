@import 'globalStyles';
@import 'styles/elements/input';

.CalendarPicker {
     &__select {
          @include input;
          @include clickable;
          box-sizing: border-box;
          min-height: 2.5rem;
          padding: 2.3rem;
          display: flex;
          align-items: center;
          position: relative;
          flex-wrap: wrap;
          max-height: 72px;
          overflow: hidden;
          overflow-y: auto;
          margin-bottom: 16px;
      
          &:hover {
            background: #fafafa;
          }
     }

     &__startIcon {
          margin-right: 8px;
     }

     &__arrow {
          position: absolute;
          right: 5px;
          transform: translateY(-50%);
          top: 50%;
      
          svg {
            fill: #000000;
          }
      
          &._expand {
              transform: translateY(-50%) rotate(-180deg);
          }
        }

     &__day {
          &._selected {
               background-color: #212121;
               color: #FFF;
          }
     }
}