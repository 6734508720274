@import 'globalStyles';

.IssueInProgress {
  display: inline-block;
  border-radius: 2rem;
  padding: 0 0.7rem 0 0.2rem;
  font-size: 0.8rem;
  line-height: 1.5rem;

  &__icon {
    width: 1rem;
    height: 1rem;
    margin: 0.2rem 0;
    margin-left: 0.1rem;
    margin-top: 0.25rem;
    vertical-align: top;
    box-sizing: border-box;
  }
}
