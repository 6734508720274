@import 'globalStyles';

.Gallery__button {
  @include clickable;
  width: 3rem;
  height: 3rem;
  z-index: 3;

  .icon {
    fill: #fff;
    width: 3rem;
    height: 3rem;
  }
}

.Gallery {
  $height: 80vh;
  min-height: 200px;

  &__content {
    overflow: hidden;
    background: #eee;
    border-radius: 2px;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    margin: 1rem 0;
  }

  &__next {
    @extend .Gallery__button;
    @include rightPosition;
  }

  &__prev {
    @extend .Gallery__button;
    @include leftPosition;

    .icon {
      transform: rotate(-180deg);
    }
  }
}