@import 'globalStyles';

.DownloadButton {
  .Loader {
    @include centerPosition;
    display: none;
    stroke-width: 8px;
    width: 1rem;
    height: 1rem;
  }

  &._loading {
    pointer-events: none;

    .Loader {
      display: block;
    }

    span {
      opacity: 0;
    }
  }

  &._alignEnd {
    margin-left: auto;
  }
}
