@import 'globalStyles';

.GeolocationStatus {
  margin-top: 0.1rem;

  &._inside {
    fill: #309B33;
  }

  &._outside {
    fill: #FF0000;
  }

  &._not_available {
    fill: #9F9F9F;
  }
}