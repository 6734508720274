@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}

@mixin for-small-phone-only {
  @media (max-width: 320px) { @content; }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}

@mixin for-tablets-and-phones {
  @media (max-width: 1199px) {
    @content
  }
}
