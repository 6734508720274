$mainBg: #F1F2F2;
$cardBg: #fff;

$lightColor: #888;
$inputBgColor: #fff;

$separatorColor: #ccc;
$newSeparatorColor: rgba(0, 0, 0, 0.12);

$textColor: #000514;

$fieldSeparatorColor: #DCDCDC;
$fieldNameColor: #757575;

$highlightColor: rgba(255, 255, 255, 0.1);
$darkerColor: rgba(0, 0, 0, 0.05);

$buttonFgColor: #fff;


$greenColor: #319D40;
$blueColor: #0079ff;
$redColor: #FF0029;
$yellowColor: #ffee00;

$secondary400: #121E2D;
