@import 'globalStyles';

.Button {
  &:hover {
    opacity: 0.85;
  }

  &:first-child {
    margin-left: 0;
  }

  &:disabled {
    cursor: default;
    opacity: 0.4;
  }

  &:active {
    opacity: 0.8;
  }

  &__icon {
    position: relative;
    fill: #fff;
    vertical-align: top;
    margin-left: -0.5rem;
    margin-right: 0.5rem;
    bottom: -0.05rem;
  }

  &:first-child {
    margin-left: 0;
  }

  &:active {
    opacity: 0.8;
  }

  &._ghost {
    background: transparent;
    box-shadow: inset 0 0 0 1px rgba(0, 5, 20, 0.2);
    border: 0;
  }

  &._small {
    font-size: 0.8rem;
    line-height: 1rem;
    padding: 0.3rem 0.5rem;
  }

  &._invisible {
    background: transparent;
    padding: 0;
    width: auto;
    margin-top: 1.5rem;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &._noTopMargin {
    margin-top: 0;
  }

  &._hrefColor {
    color: #0093FF;
  }
}
