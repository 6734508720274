@import 'globalStyles';

.ContentBlock{
  @include buttonsGroup;

  &__wrapper{
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__icon {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: #212121;
    margin-right: 10px;
  }

  &__title {
    flex-grow: 1;
    cursor: pointer;

    &--primary {
      font-size: 15px;
      line-height: 20px;
      color: rgba(33, 33, 33, 1);
      white-space: normal;

      &__wrapper {
        display: flex;
        gap: 6px;
      }

      &__icon {
        fill: #888888;
      }
    }

    &--secondary {
      font-size: 13px;
      line-height: 16px;
      color: rgba(136, 136, 136, 1);
      white-space: normal;
    }
  }
}
