@import 'globalStyles';
@import '../variables.scss';

.MenuLoginButton {
  padding: 1rem $padding;
  border-top : solid 1px $separatorColor;
  border-bottom: solid 1px $separatorColor;
  display: flex;
  color: inherit;
  text-decoration: none;
  position: relative;

 .icon {
   width: 1rem;
   height: 1rem;
   fill: inherit;
   stroke: #fff;
   @include rightPosition(1rem);
 }
}