/* Generated by Font Squirrel (https://www.fontsquirrel.com) on November 10, 2016 */

@font-face {
    font-family: 'merkuryregular';
    src: url('./merkury_regular-webfont.woff2') format('woff2'),
         url('./merkury_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'merkurymedium';
    src: url('./merkury_medium-webfont.woff2') format('woff2'),
         url('./merkury_medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotoregular';
    src: url('./Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotomedium';
    src: url('./Roboto-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('./IBMPlexSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('./IBMPlexSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}


@font-face {
    font-family: 'Commissioner';
    src: url('./Commissioner.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Commissioner';
    src: url('./Commissioner-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}



