@import 'globalStyles';

.DictEditor {
  &__item {
    display: flex;

    @include for-phone-only {
      display: block;
    }
  }

  &__itemName {
    flex-grow: 1;
    width: 100%;
    word-break: break-word;
  }

  &__itemActions {
    @include buttonsGroup;
    flex-shrink: 0;
    display: flex;
    align-items: center;

    @include for-phone-only {
      margin-top: 0.5rem;
    }
  }
}