@import 'globalStyles';

.SearchField {
  &__search-field {
    width: 100%;
    border: 1px solid rgba(204, 204, 204, 1);
    border-radius: 2px;
    margin-bottom: 1rem;

    input::-webkit-search-cancel-button {
      -webkit-appearance: none;
      appearance: none;
    }
  }

  &__clear-icon {
    cursor: pointer;

    &:hover {
      color: #000;
    }
  }
}
