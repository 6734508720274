@import 'globalStyles';

.Modal._galleryModal {
  z-index: 1301;
}

.PhotoGalleryModal {
  .Gallery {
    margin-top: -2rem;
    &__content {
      margin-bottom: 0;
    }
  }

  @include for-phone-only {
    .Gallery {
      margin-top: -1.5rem;

      &__content {
        margin: 1.5rem -1rem 0 -1rem;
      }
    }
  }
}