@import 'globalStyles';
@import '../variables.scss';

.MenuUserBar {
  padding: 1rem $padding;
  padding-left: 20px;
  display: flex;
  color: inherit;
  text-decoration: none;

  &__logout {
    $padding: 10px;
    margin: -$padding;
    margin-left: auto;
    cursor: pointer;
    padding: $padding;
    .icon {
      width: 1rem;
      height: 1rem;
      fill: inherit;
      stroke: inherit;
    }
  }
}
