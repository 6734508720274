@import 'globalStyles';
@import 'styles/elements/input';

.AsyncTreeViewSelect {
  position: relative;
  &__select {
    @include input;
    @include clickable;
    box-sizing: border-box;
    padding: 2.3rem;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    overflow-x: hidden;
    flex-wrap: nowrap;
    min-height: 42px;
    height: auto;
    padding: 8px 28px 8px 12px !important;

    &._multiSelect {
      overflow-y: auto;
      max-height: 72px;
    }

    &._error {
      border-color: #FF3B30;
    }

    &:hover {
      background: #fafafa;
    }
  }

  &__paper {
    border-radius: 2px;
    cursor: pointer;
  }

  &__start-icon {
    margin-right: 12px;
    margin-left: -6px;
  }

  &__placeholder {
    opacity: .5;
  }

  &__arrow {
    position: absolute;
    right: 5px;
    transform: translateY(-50%);
    top: 50%;

    svg {
      fill: #000000;
    }

    &._expand {
        transform: translateY(-50%) rotate(-180deg);
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &._only_clear {
      position: absolute;
      right: 0;
      top: -28px;
    }
  }

  &__title {
    display: flex;
  }

  &__requiredMark {
    font-size: 0.8rem;
    flex-shrink: 0;
    color: #FF3B30;
    margin-left: 4px;
  }
}

.Search {
  border-bottom: 1px solid var(--tertiary-10-gray-3, #CCC);

  &._searchGlyph {
    pointer-events: inherit;
  }

  input {
    width: 100%;
    border: 0 !important;
  }
}
