@import 'globalStyles';

.page-loader {
  @keyframes loading {
    0% {
      width: 0%;
    }

    50% {
      width: 80%;
    }

    100% {
      width: 95%;
    }
  }

  @keyframes loaded {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  z-index: 9000;

  &__fill {
    background: #b2cd29;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    transition: width 0.5s ease;

    &._progress {
      animation: loading 2s ease forwards;
    }

    &._finished {
      width: 100%;
      animation: loaded 0.5s ease forwards 0.5s;
    }
  }

  @include for-phone-only {
    top: 49px;
    height: 68px;
    opacity: 0.2;
    pointer-events: none;

    &__fill {
      background: #fff;
      filter: blur(px);
    }
  }
}
