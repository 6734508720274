@import 'globalStyles';

.Datepicker {
  & .PrivatePickersFadeTransitionGroup-root {
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
  }
}

.CalendarWrapper {
  margin-left: -42px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
