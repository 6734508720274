@import 'globalStyles';

.InventoryAudit {
  &__unexpectedItems {
    margin-top: 0.5rem;
  }

  &__unexpectedItemsTitle {
    margin-bottom: 0.5rem;
  }

  &__item {
    padding: 0.5rem 0;
    display: flex;
    font-size: 0.8rem;

    &._found {
      -webkit-text-decoration: strike;
              text-decoration: strike;
    }
  }

  &__itemType {
    flex-grow: 0;
    width: 15.625rem;
    margin-right: 1rem;

    cursor: pointer;
    text-decoration: underline;
  }
}
