
.IssueRating {
  display: flex;
  align-items: center;

  &._short {
    white-space: nowrap;

    .Rating {
      vertical-align: top;
      position: relative;
      top: 0.15rem;
      transform: scale(0.8);
    }
  }
}
