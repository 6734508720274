
@import 'globalStyles';

.PopoverWithArrow {
  &__root {
    .MuiBackdrop-root {
      background-color: transparent;
      -webkit-backdrop-filter: none;
              backdrop-filter: none;
    }
    .MuiPaper-root {
      background-color: transparent;
      box-shadow: none;
      width: 576px;

      @media (max-width: 899px) {
        width: min(576px, calc(100% - 32px));
        left: max(16px, calc((100% - 576px)/2)) !important;
      }
    }
  }

  &__wrapper {
    background-color: transparent;
    box-shadow: 0px 2px 12px 0px #2121211F;
    margin: 17px;

    @include for-tablet-landscape-up {
      &::before {
        content: "";
        display: block;
        position: absolute;
        background-color: white;
        width: 14px;
        height: 14px;
        transform: rotate(45deg);
        top: calc(50% - 7px);
        left: 11px;
      }
    }
  }

  &__content {
    position: relative;
    padding: 24px;
    background-color: white;
    border-radius: 8px;
  }

  
  &__close {
    display: block;
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    svg {
      fill: none;
      width: 24px;
      height: 24px;
    }

    &:hover {
      fill-opacity: 100%;
    }
  }
};