.ClockPicker {
     &__textFieldContainer {
          display: flex;
          font-size: 54px;
          justify-content: space-between;
     }

     &__textField {
          & .MuiInputBase-root {
          height: 80px;
          width: 127px;
          & > input {
               font-size: 46px;
               text-align: center;
               }
          }
     }

     &__timeClock {
          width: 256px;
     }

     &__timeFieldLabel {
          font-family: 'robotoregular';
          font-size: 12px;
          color: #000;
          opacity: 0.6;
          margin-top: 7px;
     }

     &__buttonGroup {
          display: flex;
          justify-content: flex-end;
     }

     &__button {
          box-shadow: none !important;
     }
}