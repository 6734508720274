@import 'input.scss';
@import 'datepicker.scss';

.bar {
  border-top: solid 1px $separatorColor;
  border-bottom: solid 1px $separatorColor;
  padding: $outerUnit;
}

.action {
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.625rem;
}
