@import 'globalStyles';

.Rating {
  $size: 1.2rem;
  position: relative;
  margin-left: -0.15rem;
  display: inline-flex;

  &__wrapper {
    display: flex;
  }

  &__icon {
    width: $size;
    height: $size;
    display: inline-block;

    fill: #222;
    opacity: 0.2;
    padding: 0 0.1rem;

    transition: opacity 0.3s ease;

    &._filled {
      fill: #222;
      opacity: 1;
    }
  }

  &__label {
    color: #797B80;
    font-size: 17px;
    line-height: 22px;
    font-family: 'robotoregular';
  }
}