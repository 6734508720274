@import 'adaptivity';
@import 'colors';
@import 'units';
@import 'mixins';
@import 'animations';
@import 'layers';

$menuWidth: 18rem;
$sidebannerWidth: 400px;

$dropdownLayer: 10;
$menuLayer: 11;

$regularFont: robotoregular;
$mediumForn: robotomedium;

@layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
}
