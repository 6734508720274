@import "globalStyles";

.MiniAppHeader {
  box-shadow: none;

  top: 49px;

  & .Logo {
    & svg {
      width: unset !important;
      height: 26px !important;
    }
  }

  padding: 10px 16px 10px;
}