@import 'globalStyles';

.EmptyPlaceholder {
  text-align: center;
  padding: 3.125rem 0;

  .icon {
    width: 17.5rem;
    height: 17.5rem;
  }

  &__text {
    margin-top: -3.125rem;
    opacity: 0.5;
    display: inline-block;
    max-width: 20rem;
  }
}
