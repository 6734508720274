@import 'globalStyles';

.ConfirmEmailModal{
  &__message {
    font-weight: 400;
    text-align: center;
    font-size: 16px;
    font-family: 'robotoregular', sans-serif;

    margin-top: 24px;
  }
  &__button {
    margin-top: 24px;
    &:disabled {
      opacity: 0.2;
      color: #fff;

      &:hover {
        opacity: 0.85;
      }
    }

    @include for-phone-only {
      width: 100%;
    }
  }
}
