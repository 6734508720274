@import 'globalStyles';

@keyframes slideDown {
  from {
    opacity: 0;
    max-height: 0;
  }

  to {
    opacity: 1;
    max-height: 50px;
  }
}

.AppVersionChecker {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 1rem;
  line-height: 40px;
  z-index: $notificationsLayer;
  background: #CCF4C3;
  text-align: center;
  border-bottom: solid 1px rgba(0,0,0,0.2);
  font-size: 0.8rem;

  animation: slideDown 0.3s ease;

  .Button {
    margin-left: 0.5rem;
  }

  @include for-phone-only {
    top: 40px;
  }
}