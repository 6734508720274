@import 'globalStyles';

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  background: rgba(#fff, 0.4);
  border-radius: 10px;
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: 1;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 8px;
}

.simplebar-scrollbar:before {
  transition: unset;
}

.simplebar-horizontal {
  display: none !important;
}
