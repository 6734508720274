@import 'globalStyles';

.Date {
  white-space: nowrap;

  &__icon {
    width: 1.2rem;
    height: 1.2rem;
    left: -0.1rem;
    position: relative;
    vertical-align: top;
    opacity: 0.4;
    margin-right: 0.1rem;
  }

  &__time {
    opacity: 0.4;
  }
}