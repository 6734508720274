@import '../FormBuilder/FormBuilder';
@import 'globalStyles';

.Field {
  @include Field;

  &__label {
    margin-bottom: 0;
  }

  &._horizontal {
    display: flex;

    .Field__label {
      width: 30%;
    }
  }

  .Modal & {
    box-sizing: content-box;
    margin-left: -$modalPadding;
    margin-right: -$modalPadding;
    padding-left: $modalPadding;
    padding-right: $modalPadding;
    padding-bottom: 1rem;
    border-bottom: solid 1px $separatorColor;

    @include for-phone-only {
      margin-left: -$mobileModalPadding;
      margin-right: -$mobileModalPadding;
      padding-left: $mobileModalPadding;
      padding-right: $mobileModalPadding;
    }
  }
}
