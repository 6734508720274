@import 'globalStyles';

.SquareBlock {
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
    /* initial ratio of 1:1*/
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}