@import 'globalStyles';

.Modal {
  &._fileUploader {

      @include for-phone-only {
          padding: 0 16px;
      }

      &>.Modal__container {
          &>.content {
              height: auto;
              width: 600px;

              @include for-phone-only {
                width: 343px;
                min-width: 250px;
            }
          }
          
      }

      & .Modal__buttons {
        justify-content: space-between;
        gap: 12px;
        margin-top: 24px;

        & button {
          width: 100%;
          align-self: stretch;
          border-radius: 8px;
        }
    }
  }

}

.FileUploader {
  background: transparent;
  border: none;
  border-radius: 3px;
  padding: 1rem 2rem;
  margin: -1rem -2rem;

  &__dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 24px;
    border-width: 0.5px;
    border-radius: 10px;
    border-color: #3878FF;
    border-style: dashed;
    background-color: #FAFBFC;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    align-items: center;
  }

  &__controls {
    display: flex;
  }

  &__dropPlaceholder {
    margin-left: 1rem;
    flex-grow: 1;
  }

  .FileInput {
    display: inline-block;
  }

  &._active {
    background: rgba(22, 255, 142, 0.13);
    border: none;
    border-radius: 3px;
  }

  &__files {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
    width: 100%;
    overflow: hidden;
  }


  &__limits {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    text-align: left;
    color: #797B80;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;

    & span {
      color: #292929;
    }

    & svg {
      margin-right: 8px;
    }
  }

  &__save {
    position: relative;
    width: 8rem;
    text-align: center;

    .Loader {
      width: 20px;
      @include leftPosition(1rem);
      stroke-width: 6px;
      margin-top: 0.5px;
    }

    &._isProcessing {
      opacity: 1 !important;
      padding-left: 2.5rem;
      -webkit-user-select: none;
         -moz-user-select: none;
              user-select: none;
    }
  }

  &__text {
    color: #292929;
    margin-bottom: 12px;
  }
  
  &__textAction {
    color: #3878FF;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;  
    cursor: pointer;
  }

  &__textAction._disabled {
    color: #A9ADB2;
    pointer-events: none;
  }

  &__notification {
    color: #797B80;
    font-size: 15px;
    line-height: 20px;
    font-style: normal;
    margin-bottom: 8px;
  }

  &._isProcessing {
    .PhotoPreview__remove {
      display: none;
    }

    .PhotoUploader__files,
    .PhotoUploader__controls {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  @include for-phone-only {
    background: none;
    border: none;
    margin: 0 -1rem;
    padding: 1rem;

    &__controls {
      flex-direction: column;
    }

    .FileInput {
      margin-bottom: 1rem;
    }

    &__dropPlaceholder {
      display: none;
      margin-left: 0;
    }

    &__files {
      min-height: 20vw;
    }
  }
}
