@import 'globalStyles';

.QuickLogin {

  &__submitButton {
    margin-top: 0.5rem;
  }

  &__wrap {
    @include overlay;
    position: inherit;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    display: flex;
    align-items: center;
  }

  $width: 21rem;
  text-transform: none;

  &__accept {
    font-size: 0.8rem;
  }

  .Button {
    width: 100%;
  }

  input {
    width: 100%;
    transition: all 0.3s ease;
  }

  &__container {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    @include for-phone-only {
      margin: 0;
      width: 100%;
    }
  }

  .Logo {
    margin-bottom: 1.5rem;
  }

  @include for-tablets-and-phones {
    &__container {
      width: 100%;
    }
  }

  @include for-tablets-and-phones {
    &__container {
      width: 100%;
    }
  }

  &__heading {
    color: var(--secondary-400, #121E2D);
    font-family: Commissioner, serif;
    font-size: 26px;
    margin-bottom: 16px;
    margin-top: 32px;
  }

  .PhoneInput,
  .MuiOutlinedInput-root {
    border-radius: 6px;
    outline: 1px solid var(--tertiary-300, #E7EEFF);
    background: var(--White, #FFF);
    font-family: IBM Plex Sans, serif;
    input {
      border: none;
      background: transparent;
    }

    .ConfirmCodeInput {
      input {
        border: 1px solid #E7EEFF;
      }
    }

    & .PhoneInputCountrySelectArrow {
      display: none;
    }
  }

  & .FormField {

    &__hint {
      color: var(--secondary-100, #39506C);
      font-family: IBM Plex Sans, sans-serif;
      font-size: 13px;
      font-weight: 400;
    }

    &__label {
      color: #121E2D;
      font-family: IBM Plex Sans, serif;
      font-size: 13px;
      font-weight: 500;
    }

    & .inputContainer {
      align-items: flex-start;
    }
  }
}
