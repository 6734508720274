.SelectedValue {
  &__value-box {
    background-color: #fff;
    min-height: 42px;
    border: 1px solid #ccc;
    border-radius: 2px;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    padding: 8px 12px;
    display: flex;
    flex-flow: row;
    box-sizing: border-box;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
  }

  &__placeholder {
    color: #000514;
    opacity: 0.3;
    font-size: 16px;
    font-weight: 400;
  }

  &__tags-container {
    display: flex;
    flex-flow: row;
    gap: 6px;
    height: 100%;
    align-items: center;
    flex-wrap: wrap;
  }

  &__caret-container {
    width: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__caret-icon-container {
    width: 20px;
    height: 20px;

    svg {
      height: 100%;
      width: 100%;
    }

    &._isOpen {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}
