@import 'globalStyles';

.TimeSlots {
  &__title {
    align-items: center;
    color: $fieldNameColor;
    margin-bottom: 0.2rem;
    display: flex;
  }

  &__slot {
    background: #F1F2F2;
    border: 1px solid rgba(196, 205, 217, 0.54);
    border-radius: 1.5rem;
    color: black;

    @media (max-width: 899px) {
      border-radius: 12px;
    }
  }

  &__gridContainer {
    & > .MuiGrid-item {
      margin-top: 16px;
      padding-top: 0;
    }
  }

  &__slotWrapper {
    padding-left: 0 !important;
    padding-right: 16px;
    
    &._start {
      background: linear-gradient(90deg, transparent 50%, lightgray 50%);

      @media (min-width: 900px) {
        &:nth-child(6n), &:last-child {
          background: unset;

          button {
            border-radius: 1.5rem 0 0 1.5rem;
          }
        }
      }

      @media (max-width: 899px) {
        &:nth-child(4n), &:last-child {
          background: unset;

          button {
            border-radius: 1.5rem 0 0 1.5rem;
          }
        }
      }
    }

    &._end {
      background: linear-gradient(90deg, lightgray 50%, transparent 50%);

      @media (min-width: 900px) {
        &:nth-child(6n + 1):not(:nth-child(1)) {
          background: unset;

          button {
            border-radius: 0 1.5rem 1.5rem 0;
          }
        }
      }

      @media (max-width: 899px) {
        &:nth-child(4n + 1):not(:nth-child(1)) {
          background: unset;

          button {
            border-radius: 0 1.5rem 1.5rem 0;
          }
        }
      }
    }

    &._inRange {
      background: lightgray;

      button {
        box-shadow: none !important;

        &:hover {
          background: lightgray;
        }
      }

      @media (min-width: 900px) {
        &:nth-child(1) {
          background: unset;

          button {
            border-radius: 0;
            background: lightgray;
          }
        }

        &:nth-child(6n) {
          background: linear-gradient(90deg, lightgray 50%, transparent 50%);

          button {
            background: lightgray;
            border-radius: 0;
          }
        }

        &:nth-child(6n + 1):not(:nth-child(1)) {
          background: linear-gradient(90deg, transparent 50%, lightgray 50%);

          button {
            background: lightgray;
            border-radius: 0 1.5rem 1.5rem 0;
          }
        }
      }

      @media (max-width: 899px) {
        &:nth-child(1) {
          background: unset;

          button {
            border-radius: 0;
            background: lightgray;
          }
        }

        &:nth-child(4n) {
          background: linear-gradient(90deg, lightgray 50%, transparent 50%);

          button {
            background: lightgray;
            border-radius: 0;
          }
        }

        &:nth-child(4n + 1):not(:nth-child(1)) {
          background: linear-gradient(90deg, transparent 50%, lightgray 50%);

          button {
            background: lightgray;
            border-radius: 0 1.5rem 1.5rem 0;
          }
        }
      }
    }
  }

  &__day {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: start;
    @include noselect()
  }

  &__dayName {
    display: flex;
    justify-content: center;

    width: 56px;

    @include for-phone-only {
      width: 40px;
    }
  }

  &__dayButtonWrapper {
    width: 56px;
    height: 56px;
  }

  @include for-phone-only {
    &__dayButtonWrapper {
      width: 40px;
      height: 40px;
    }
  }

  &__dayButton {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    @include noselect();
    text-align: center;
    line-height: 12px;
    padding: 0;

    &:hover:not(&._selected) {
      background: rgba(0, 0, 0, 0.05) !important;
    }
  }

  &__dayButtonContent {
    display: flex;
    flex-direction: column;
  }

  &__dayBusyness {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    position: absolute;
    bottom: 6px;

    &._low {
      background: #34C759;
    }

    &._average {
      background: #FFCC00;
    }

    &._high {
      background: #FF3B30;
    }
  }
}
