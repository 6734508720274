@import "globalStyles";

.SharingDaySlots {
  &__hint {
    width: calc((50px * 7) + (8px * 6));
    margin-bottom: 1rem;

    @include for-phone-only {
      width: calc((35px * 7) + (8px * 6));
    }

    @include for-small-phone-only {
      width: calc((30px * 7) + (8px * 6));
    }
  }
}