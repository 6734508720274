@import 'globalStyles';

.ExpiredIssue {
  background: rgba(255,0,0,0.1);
  display: inline-block;
  border-radius: 2rem;
  padding: 0 0.7rem 0 0.3rem;
  font-size: 0.8rem;
  line-height: 1.5rem;

  &__icon {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.15rem 0;
    fill: red;
    vertical-align: top;
    opacity: 0.6;
    margin-right: 0.2rem;
    margin-left: -0.1rem;
  }
}