@import 'globalStyles';

.ListInput {
  border: solid 1px $separatorColor;

  &._empty {
    border-bottom: 0;
  }

  &__inputWrapper {
    display: flex;
    align-items: stretch;
    border-bottom: solid 1px $separatorColor;
  }

  &__input {
    flex-grow: 1;
    border-width: 0 1px 0 0 !important;
    border-radius: 0 !important;
  }

  &__addValue {
    box-shadow: none !important;
  }

  &__value {
    display: flex;
    padding: 0.5em;
    word-break: break-word;

    &:hover {
      background: rgba(#000, 0.05);
    }
  }

  &__valueText {
    width: 100%;
  }

  &__removeValue {
    cursor: pointer;
  }
}
