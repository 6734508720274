@import 'globalStyles';

.Locations {
  &__actions {
    margin-top: 1rem;
    @include buttonsGroup;

    .LocationsFileUploader Button {
      border-radius: 0 3px 3px 0;
      padding: 0.5rem;
    }
  }

  &__busy {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
}
