@import 'globalStyles';

.Entry {
  min-width: 0;

  grid-area: entry;
  padding: 16px;
  margin: 250px auto 0;
  align-items: center;
  justify-content: center;

  &__logo {
    display: none;
  }

  &__heading {
    font-size: 26px;
    line-height: 36px;
    font-weight: 600;
    color: var(--secondary-400, #121E2D);
  }


  .Button {
    width: 100%;
    border-radius: 6px;
    padding: 12px 24px;
  }

  &__container {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 450px;
    margin-top: 32px;
  }

  & .Logo {
    margin-bottom: 1.5rem;

    svg {
      background-color: transparent;
    }
  }

  &__actionText {
    margin-bottom: 1rem;
  }

  & .MuiTab-root {
    padding: 0;
    font-family: Commissioner, serif;
    font-size: 15px;
    text-transform: none;
    font-weight: 500;
  }

  & .MuiTabs-root {
    margin-bottom: 32px;
  }

  & .MuiTabs-flexContainer {
    gap: 24px;
    border-bottom: 1px solid #E7EEFF;
  }

  .PhoneInput,
  .MuiOutlinedInput-root {
    border-radius: 6px;
    outline: 1px solid var(--tertiary-300, #E7EEFF);
    background: var(--White, #FFF);
    font-family: IBM Plex Sans, serif;
    input {
      border: none;
      background: transparent;
    }

    .ConfirmCodeInput {
      input {
        border: 1px solid #E7EEFF;
      }
    }

    & .PhoneInputCountrySelectArrow {
      display: none;
    }
  }

  & .FormField {

    &__hint {
      color: var(--secondary-100, #39506C);
      font-family: IBM Plex Sans, sans-serif;
      font-size: 13px;
      font-weight: 400;
    }

    &__label {
      color: #121E2D;
      font-family: IBM Plex Sans, serif;
      font-size: 13px;
      font-weight: 500;
    }

    & .inputContainer {
      align-items: flex-start;
    }
  }

  .FormButtons {
    margin-bottom: 16px;
  }

  &__entryFooter {
    display: none;
    @include for-tablets-and-phones {
      display: block;
      background: #F3F7FF;
      border-radius: 8px 8px 0 0;
      padding: 16px 24px;
      width: 100%;
      box-sizing: border-box;

      margin-top: 24px;
    }
  }

  @include for-tablets-and-phones {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;

    &__container {
      width: auto;
    }

    &__logo {
      display: block;
      height: 68px;
    }

    &__heading {
      font-size: 22px;
    }

  }

  @include for-tablets-and-phones {
    margin: 0 32px;
  }

  @include for-phone-only {
    margin: 0 16px;
  }
}