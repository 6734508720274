.ChangePassword {
  max-width: 40rem;
  padding: 1rem 0;

  &__title {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  input {
    width: 100%;
  }
}
