@import './colors';
@import './adaptivity';

@mixin center-position {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

@mixin overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin centerPosition {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

@mixin rightPosition ($rightOffset: 0) {
  position: absolute;
  top: 50%;
  right: $rightOffset;
  transform: translateY(-50%);
}

@mixin leftPosition ($leftOffset: 0) {
  position: absolute;
  top: 50%;
  left: $leftOffset;
  transform: translateY(-50%);
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}

@mixin clickable {
  cursor: pointer;
  user-select: none;

  &:active {
    opacity: 0.8;
  }
}

@mixin highlightable {
  &:hover {
    background: $darkerColor;
  }
}

@mixin capitalize {
  &:first-letter {
    text-transform: uppercase;
  }
}

@mixin label {
  border-radius: 3px;
  background: #eee;
  padding: 0.2rem 0.5rem;
  white-space: nowrap;
}

@mixin dropdown {
  box-sizing: border-box;
  position: absolute;
  top: 100%;
  width: 100%;
  max-height: 22rem;
  overflow: auto;
  border: solid 1px $separatorColor;
  z-index: $dropdownLayer;
}

@mixin buttonsGroup {
  & > * {
    display: inline-block;
    margin-left: -1px;
  }

  button {
    border-radius: 0;
  }

  & > :first-child, & > :first-child button {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }

  & > :last-child, & > :last-child button {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }
}

@mixin disabled {
  opacity: 0.5;
  pointer-events: none;
}

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color;
  background: rgba($color, $opacity);
}

@mixin noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
