@import 'globalStyles';

.Notifications {
  @keyframes showNotification {
    from {
      max-height: 0;
      transform: translateY(-2rem);
      opacity: 0;
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes hideNotification {
    from {
      transform: translateY(0);
      opacity: 1;
    }

    to {
      max-height: 0;
      transform: translateY(-2rem);
      opacity: 0;
    }
  }

  position: fixed;
  z-index: $notificationsLayer;
  font-size: 0.8rem;

  @include for-tablet-portrait-up {
    top: 1rem;
    right: 1rem;
  }

  &._center {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  &__item {
    background: #fff;
    box-shadow: 0px 2px 12px 0px rgba(136, 136, 136, 0.10);
    width: 16rem;
    padding: 12px 12px 18px 12px;
    margin-bottom: 4px;
    border-radius: 2px;

    color: rgba(33, 33, 33, 1);


    animation: showNotification 0.3s ease forwards;

    display: flex;
    align-items: flex-start;

    p {
      align-self: center;
      flex-grow: 1;

      a {
        color: rgba(33, 33, 33, 1) !important;
        font-weight: 500;
      }
    }

    &._error {
      color: rgba(33, 33, 33, 1);
      background: #fff;
      box-shadow: 0px 2px 12px 0px rgba(136, 136, 136, 0.10);
    }
  }

  &__icon {
    width: 32px;
    height: 32px;
    padding: 4px;
    border-radius: 6px;
    background: rgba(56, 120, 255, 1);
    box-shadow: 0 4px 12px 0 rgba(56, 120, 255, 0.2);
    margin-right: 12px;

    path {
      fill: white;
    }

    &._error {
      background: rgba(255, 59, 48, 1);
      box-shadow: 0 4px 12px 0 rgba(255, 59, 48, 0.2);
    }
  }

  .Notification-leave.Notifications__item {
    animation: hideNotification 0.4s ease forwards;
  }
}
