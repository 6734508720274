.ActionsBlock {
  &__wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  &__inline-icon {
    cursor: pointer;
    padding-left: 8px;
    display: flex;
    align-items: center;

    &._currentColor svg {
      path {
        stroke: currentColor;
      }
    }

    &._menuIcon svg {
      fill: rgba(136, 136, 136, 1);
    }

    svg {
      fill: transparent;
      width: 24px;
      height: 24px;
    }
  }

  &__menu-popover {
    background: #fff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    border: 1px solid rgba(204, 204, 204, 1);

    .FileInput__button {
      border-radius: 0;
      box-shadow: none;
    }

    .MuiPopover-paper {
      border: 1px solid rgba(0, 5, 20, 0.2);
    }
  }

  &__menu-items-container {
    display: flex;
    flex-flow: column;

    & > *:not(:last-child) {
      border-bottom: 1px solid rgba(0, 5, 20, 0.2);
    }
  }

  &__menu-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    padding: 0 4px;

    &:hover {
      cursor: pointer;
      background: rgba(0, 0, 0, 0.04);
    }
  }
}
