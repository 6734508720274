@import 'globalStyles';

.EntryModal {}

.Modal._login {
  @include for-tablet-portrait-up {
    .Modal__window {
      width: auto;
    }
  }

  .Entry__container {
    padding: 0;
  }

  .Modal__title {
    display: none;
  }

  .Entry {
    margin-top: -0.1875rem;

    @include for-phone-only {
      margin-top: 0.9375rem;
    }
  }
}
