@import 'globalStyles';

.Search {
  position: relative;

  .icon {
    opacity: 0.5;
  }

  &._searchGlyph {
    pointer-events: none;
  }
}

.invisibleFocus {
  border: 0;
}