@import 'globalStyles';
@import '../styles/elements/input';

.datepicker input {
    padding-left: 2.5rem!important;
}

.react-datepicker{
    &-popper{
        z-index: 100!important;
    }

    &-wrapper{
        display: flex!important;
        flex-grow: 1!important;
    }

    &__input-container{
        display: flex!important;
        flex-grow: 1!important;

        input{
            display: flex;
            flex-grow: 1;
            @include placeholder {
                color: #757575;
            }
        }
    }

    &__time-list {
        width: 99% !important;
    }

    &__header {
        margin-bottom: 0;
    }
}

.DateTimeCalendarWrapper {
    margin-left: -42px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}
