.Modal {
  iframe {
    border: 0px;
    height: 70vh;
    width: 100%;
  }

  .responsive-img {
    display: block;
    width: 100%;
  }
}
