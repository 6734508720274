@import 'globalStyles';

.AcceptTermsText {
  color: #39506C;
  font-family: IBM Plex Sans, serif;
  text-align: left;
  margin-top: 24px;

  &._miniApp {
    margin-top: 24px;
    text-align: left;
    font-size: 12px;
    line-height: 16px;
  }

  & a {
    text-decoration: none;
    color: #0093FF;
  }
}
