@import 'globalStyles';

.AttributesForm {
  & > .FieldValue {
    border-bottom: none !important;
    margin-bottom: 1rem;
  }

  &:empty {
    display: none;
  }

  .FormField._Number {
    .FormField__input > div {
      width: 100%;

      & > input {
        width: 100%;
      }
    }
  }

  .FormField._RadioButtons,
  .FormField._CheckboxGroup {
    .FormField__input > div {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-left: 0.5rem;
    }

    .Radio {
      margin-bottom: 0.5rem;
      display: inline-block;
    }
  }

  &__photoText {
    font-family: $regularFont;
    margin-bottom: 2rem;

    ._required + & {
      color: $redColor;
    }
  }
}
