@import 'globalStyles';

.DocumentPreview {

  &._clickable {
    @include clickable;
  }

  &__content {
    margin-right: 1.5rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__link {
    margin-right: 0.5rem;
  }

  &__remove {
    @include clickable;
    padding: 3px;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    border-radius: 0 0 0 2px;

    stroke-width: 2px;
    stroke: rgba(0, 0, 0, 0.7);
    fill: #fff;
  }
}