.RoundReportsFilters {
  &__dates {
    display: flex;
    align-items: flex-end;
    gap: 1rem;

    .FormField {
      width: 10rem;
    }
  }

  &__users_group {
    display: flex;
    gap: 1rem;
    flex-grow: 1;
  }

  &__users {
    min-width: 300px;
  }

  &__authors {
    min-width: 300px;

    & .FormField__clear {
      right: 1px;
    }
  }

  &__number, &__more-less {
    width: 10rem;
  }

  & .FormField__label {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    color: #667790;
  }

  &__more-less {
    & .MuiSelect-icon {
      display: none;
    }
  }

  &__additional {
    width: 100%;
    display: flex;
    gap: 1rem;
  }
}
