@import 'globalStyles';

.NotificationPage {
  &__chunk {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .List__noResults {
      display: none;
    }

    .List__item {
      display: block;
      border-bottom: none;
      padding: 0;

      border-radius: 2px;
      box-shadow: 0px 2px 12px 0px rgba(11, 17, 26, 0.08);

      @include for-phone-only {
        border-radius: 10px;
        box-shadow: none;
        border: 0.5px solid #E0E2E6;
      }
    }

    &._loading {
      text-align: center;
      padding: 1rem 0 0.5rem 0;
      border-bottom: none;

      .Loader {
        opacity: 0.4;
        stroke: #000;
        stroke-width: 4px;
        width: 4rem;
        height: 4rem;
      }

      @include for-phone-only {
        margin: 0 -1rem;
      }
    }
  }

  @include for-phone-only {
    background-color: #fff;

    &__title {
      font-size: 22px;
    }

    &__chunk {
      padding: 0;
      gap: 0;
    }
  }
}

.TextButton {
  @include for-phone-only {
    font-size: 13px;
    text-align: right;
  }
}

