.TextButton {
  font-family: 'robotoregular';
  background: transparent;

  padding: 0;
  &:hover {
    background-color: transparent;
  }

  &._info{
    color: #3878FF;
  }

  &._error {
    color: #FF3B30;
  }

  &._primary {
    color: #888888;
  }

  &._underline {
    text-decoration: underline;
  }
}
