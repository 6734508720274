@import 'globalStyles';

.UserRoles {

    &__container {
        height: calc(100vh - 140px);
        overflow-y: scroll;

        @media (max-width: 1200px) {
            height: calc(100vh - 200px);
        }
    }
    
    &__system {
        margin-bottom: 1rem;
        
        & .List__item {
            cursor: default;
        }
    }

    &__listTitle {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 1rem;
    }

    &__item {
      display: flex;
  
      @include for-phone-only {
        display: block;
      }
    }

    &__itemInfo {
        flex-grow: 1;
    }
  
    &__itemName {
      width: 100%;
      word-break: break-word;
    }

    &__itemDescription {
        width: 100%;
        word-break: break-word;

        color: $fieldNameColor;
    }
  
    &__itemActions {
      @include buttonsGroup;
      flex-shrink: 0;
  
      @include for-phone-only {
        margin-top: 0.5rem;
      }
    }
  }