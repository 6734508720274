@import 'globalStyles';

.Modal._verifyLocation {
  .Modal__window {
    width: 30rem;
  }
}

.VerifyLocation {
  &__description {
    margin-bottom: 1rem;
  }
}