@import 'globalStyles';

.IssueRemainingTime {
  display: inline-block;
  border-radius: 2rem;
  padding: 0 0 0 0.3rem;
  font-size: 0.8rem;
  line-height: 1.5rem;

  &__icon {
    width: 1rem;
    height: 1rem;
    margin: 0.25rem 0;
    vertical-align: top;
    margin-right: 0.2rem;
  }

  &._overdue {
    .IssueRemainingTime__icon {
      fill: red;
    }
  }

  &__wrapper {
    display: inline-block;
  }
  &__status {
    display: block;
    color: red;
    opacity: 0.6;
  }
}
