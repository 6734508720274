.ScrollableList {
  position: relative;

  &__loader-container {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
  }

  &__list {
    overflow: auto;
    display: flex;
    flex-flow: column;
    overscroll-behavior-block: contain;
    max-height: 300px;

    &._loading {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__trigger-element {
    position: relative;
    height: 0px;
  }
}
