@import 'globalStyles';

.RoundGroup {

  &__title {
    text-transform: none;
    color: #112950;

    &__name {
      display: flex;
      align-items: center;

      font-size: 20px;
      line-height: 32px;
      margin-top: 10px;

      &_title {
        margin-right: 10px;
      }

    }

    &__location {
      font-size: 16px;
      line-height: 24px;
      color: #667790;

      margin-top: 10px;
    }
  }

  &__date {
    display: flex;
    gap: 10px;
    align-items: center;
    position: relative;
    color: #667790;
    font-size: 12px;
    line-height: 20px;

    & .Date__time {
      opacity: 1;
      margin-left: 20px;
      position: relative;

      &::before {
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #3878FF;

        position: absolute;
        left: -10px;
        top: 4px;
      }
    }

  }

  &__overdue {
    display: flex;
    gap: 5px;
    font-size: 12px;
    line-height: 20px;

    color: #ff5252;
  }

  &__child {
    box-shadow: none;

    &::before {
      height: 0 !important;
    }

    &__summary {
      margin-left: -$modalPadding;
      margin-right: -$modalPadding;
      padding: 0 3rem;

      background-color: #FBFCFF;
      border-top: 1px solid rgba(231, 238, 255, 0.72);
      border-bottom: 1px solid rgba(231, 238, 255, 0.72);

      @include for-phone-only {
        margin-left: -$mobileModalPadding;
        margin-right: -$mobileModalPadding;
        padding: 0 2rem;
      }
    }
  }

  &__no_data {
    color: #667790;
    font-weight: 400;
  }

  & .Attributes {
    margin-bottom: 1rem;
    & .Attribute {
      &:not(:first-child) {
        margin-top: 16px;
      }

      & .FieldValue__name {
        color: #667790;
      }

      & .FieldValue__value {
        color: #112950;
      }
    }
  }

  &__point-title {
    display: flex;

    div {
      margin-left: 10px;
    }

    &.closed {
      color: $greenColor;
    }
    &.open {
      color: $lightColor;
    }
  }
}
