@import 'globalStyles';

.IssueComment {
    color: #0B111A;
    margin-top: 4px;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
    white-space: nowrap;
    font-family: 'robotoregular';

    @include for-phone-only {
        max-width: 200px;
    }

    @include for-tablet-portrait-up {
        max-width: 18.75rem;
    }

    @include for-tablet-landscape-up {
        max-width: 31.25rem;
    }

    @include for-desktop-up {
        max-width: 43.125rem;
    }

}


