@import 'globalStyles';

.Login {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;

  input {
    width: 100%;
    transition: all 0.3s ease;
  }

  .FormField._invalid input {
    background-color: #fff;
  }

  .Button {
   margin-top: 8px;

    .icon {
      margin-right: 1rem;
    }
  }
}

.Login._isTouched._invalid ._invalid input {
  box-shadow: 0 0 1px 1px red inset;
  background-color: #ffeffa;
}
