@import 'globalStyles';

.RoleRelationsList {
    margin-bottom: 15px;

    &__title {
        margin-bottom: 8px;
    }

    &__list {
        list-style: inside;
        text-align: left;
        margin-left: 10px;
        margin-bottom: 8px;
        font-size: 15px;
        font-family: 'robotoregular';
    }
  }