@import 'globalStyles';

.IssueStatus {
  background: rgba(0,0,0,0.05);
  display: inline-block;
  border-radius: 2rem;
  padding: 0 0.5rem 0 0.5rem;
  font-size: 0.8rem;
  line-height: 1.5rem;
  white-space: nowrap;

  &._closed {
    background: rgba(100,180,0,0.1);
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 4px;

    .icon {
      vertical-align: top;
      width: 0.8rem;
      height: 0.8rem;
    }
  }

  &._opened {
    height: 1.5rem;
  }

  &._moderation {
    background-color: rgba(231, 238, 255, 0.72);
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;

    &.overdue {
      background-color: rgba(255, 59, 48, 0.18);
    }
  }

  &.on_check {
    background: #888;
  }

  &__name {
    @include capitalize;
  }
}