@import 'globalStyles';

.IssueCloseDate {
  font-size: 0.8rem;
  display: flex;
  align-items: center;

  .icon {
    margin: 0 0.2rem 0 0.3rem;
    vertical-align: top;
    width: 0.8rem;
    height: 0.8rem;
    fill: $greenColor;
  }

  .Date {
    margin-left: 0.3rem;
  }
}
