@import 'globalStyles';

.location-select {
  &._withMy {
    .TreeViewSelect {
      &__dropdown {
        padding-top: 5rem;
      }

      &__filter {
        background: #fff;
        border-bottom: solid 1px $separatorColor;

        .Search input {
          border-bottom-color: #eee;
        }

        .Checkbox {
          margin: 0.5rem;
        }
      }
    }
  }
}
