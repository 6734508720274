.icon {
  width: var(--icon-width);
  height: var(--icon-height);
}

.icon.MuiIconButton-root {
  color: unset;
  padding: 0;
}

.Icon-fullSize {
  width: unset !important;
  height: unset !important;
}

.Icon-hidden {
  visibility: hidden;
}
