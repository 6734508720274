@import 'globalStyles';

.ConfirmCodePage {
    ._ConfirmCode {
        margin-bottom: 32px;
    }

    &__description {
        margin: 0 auto 3rem;
        text-align: center;
        letter-spacing: 0.2px;
    }

    &__submitButton {
        margin-bottom: 16px;
    }

    &__label {
        display: flex;
        gap: 4px;
        color: #121E2D;
        font-family: IBM Plex Sans, sans-serif;
        font-weight: 500;
        margin-bottom: 6px;
        align-items: center;

        svg {
            fill: transparent;
        }
    }

    &__timer {
        font-size: 0.875rem;
        line-height: 1rem;
        color: rgba(0, 0, 0, 0.6);
        text-align: center;
        padding: 12px 24px;

    }

    

    &__repeatButton {
        margin-top: 0 !important;
        color: #0093FF; 
        box-shadow: none !important; 
        background: transparent !important;

    }

    &__edit_icon {
        color: transparent;
        padding: 0 4px !important;
        margin-bottom: 2px;
    
        & svg {
          width: 16px;
          height: 16px; 
        }
      }
}
