@import 'globalStyles';

.FileSelect {
  border-radius: 3px;
  display: flex;

  &__title {
    margin-top: 0.5rem;
  }

  &__addButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 62px;
    height: 62px;
    border-radius: 10px;
    border: 0.5px dashed #E0E2E6;
    background:  #FAFBFC;
    cursor:pointer;

    &:hover {
      background-color: $mainBg;
    }
  }
}