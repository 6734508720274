@import 'globalStyles';

.InventoryReportFilter {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 30px;

  padding: 16px;
  margin-bottom: 16px;

  border-radius: 12px;
  border: 1px solid rgba(231, 238, 255, 0.72);
  box-shadow: 0px 1px 12px -2px rgba(231, 238, 255, 0.64);

  & .FormField {
    max-width: 10rem;
    margin-bottom: 0;

    &:first-child {
      &::after {
        content: '';
        width: 10px;
        height: 2px;
        background: $lightColor;

        position: absolute;
        right: -20px;
        bottom: 20px;
      }
    }
  }

  &__stocks {
    max-width: 20rem!important;
  }
}
