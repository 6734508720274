@import 'globalStyles';
@import '../variables.scss';

.MenuItem {
  position: relative;
  display: block;
  transition: color,
  background 0.2s ease;

  @include for-tablets-and-phones {
    //padding: 0.2rem 0;
  }

  &__icon {
    fill: inherit;
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    vertical-align: top;
  }

  a {
    @include clickable;
  }

  &__link {
    transition: opacity 0.2s ease;
    color: inherit;
    text-decoration: none;
    padding: 10px $padding 10px 20px;
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    @include for-desktop-up {
      &:hover {
        background: $highlightColor;
        border-radius: 8px;
      }
    }
  }

  ._hidden {
    padding-left: 12px;
  }

  &__text {
    display: inline-block;
    font-size: 16px;
  }

  ._withIcon {
    margin-left: 1rem;
  }
}
