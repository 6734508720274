@import "globalStyles";

.slick-arrow {
  width: 30px !important;
  height: 30px !important;
  background: rgba(0,0,0,0.5) !important;
  border-radius: 35px;
  z-index: 1000;
  top: calc(24px + ((56px / 2) - ((30px / 2) + 4px))) !important;

  @include for-phone-only {
    top: calc(24px + ((40px / 2) - ((26px / 2) + 4px))) !important;
  }

  @include for-phone-only {
    width: 26px !important;
    height: 26px !important;
  }

  &:hover {
    background: rgba(0,0,0,0.5) !important;
  }

  &:focus {
    background: rgba(0,0,0,0.5);
  }

  &.slick-prev {
    transform: rotate(180deg) !important;
    left: 0 !important;
  }

  &.slick-next {
    transform: unset !important;
  }

  &.slick-next {
    right: 0;
  }

  &:before {
    content: none !important;
  }

  svg {
    width: 30px;
    height: 30px;
    fill: white;

    @include for-phone-only {
      width: 26px !important;
      height: 26px !important;
    }
  }
}

.with-padding-left {
  padding-left: 38px;

  @include for-phone-only {
    padding-left: 34px;
  }
}

.slick-slider {
  padding-right: 38px;

  @include for-phone-only {
    padding-right: 34px;
  }
}