.WarningModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  font-family: 'robotoregular';
  font-size: 15px;
  line-height: 20px;

  &__icon {
      width: 40px;
      height: 40px;
      fill: #FDD231;
  }

  &__title {
      font-size: 17px;
      line-height: 22px;
  }

  &__subtitle {
      text-align: center;
  }

  &__button {
      width: 100%;
      border-radius: 10px;
      margin-top: 8px;
      font-size: 17px;
      line-height: 22px;
      padding: 13px 16px;
  }
}