@import 'globalStyles';

.FieldValue {
  width: 100%;
  position: relative;

  &__name {
    @include capitalize;
    color: $fieldNameColor;
    &:first-letter {
      text-transform: none;
    }
  }

  &__value {
    @include capitalize;
    &:first-letter {
      text-transform: none;
    }
  }

  page-break-inside: avoid;
}