@import 'ui/Label/Label.scss';

.LocationLabel {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #F5F5F5;
  border-radius: 2px;
  border: 1px solid #CCC;

  padding: 10px 12px;

  &__icon {
    width: 20px;
    height: 20px;
    margin-bottom: -4px;
    margin-right: 6px;
  }

  .LocationFullPath {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
