.ListItem {
  padding: 8px 16px;

  @media (hover: hover) {
    &:hover {
      background: #f8f8f8;
      cursor: pointer;
    }
  }
}
