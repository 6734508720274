.Select {
  &__header {
    display: flex;
    height: 24px;
    justify-content: space-between;
  }

  &__dropdown-container {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-shadow: 0 2px 3px 3px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    margin-top: 8px;
  }
}
