@import 'globalStyles';
@import 'variables.scss';

.Menu {

  &__badge {

    & .MuiBadge-badge {
      width: 6px;
      height: 6px;
      min-width: 6px;
    }
   
  }

  @media (min-width: 1200px) {
    padding: 2*$unit 0;
  }

  @include for-phone-only {
    width: 100%;
  }

  transition: max-height 0.3s ease;
  z-index: $menuLayer;

  &__importantMark {
    width: 8px;
    height: 8px;
    border-radius: 10px;
    background: #ff4a4a;
    opacity: 1;
    @include rightPosition(1rem);
  }

  .Menu-items {
    &> div {
      @include for-phone-only {
        bottom: 50px !important;
      }
    }
  }
}
