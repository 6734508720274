@import "globalStyles";

.DaySlots {
  &__title {
    align-items: center;
    text-transform: capitalize;
    color: $fieldNameColor;
    margin-bottom: 0.2rem;
    display: flex;
    width: calc((50px * 7) + (8px * 6));

    @include for-phone-only {
      width: calc((35px * 7) + (8px * 6));
    }

    @include for-small-phone-only {
      width: calc((30px * 7) + (8px * 6));
    }
  }

  &__navigationArrow {
    padding: 4px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__row {
    display: flex;
  }

  &__slotWrapper {
    width: 58px;
    text-align: center;
    padding-right: 8px;
    margin-top: 8px;

    &._monthDay {
      height: 50px;

      @include for-phone-only {
        height: 35px
      }

      @include for-small-phone-only {
        height: 30px
      }
    }

    &._start {
      background: linear-gradient(90deg, transparent 50%, lightgray 50%);

      &:nth-child(7n), &:last-child {
        background: unset;

        button {
          border-radius: 1.5rem 0 0 1.5rem;
        }
      }
    }

    &._end {
      background: linear-gradient(90deg, lightgray 50%, transparent 50%);

      &:nth-child(7n + 1) {
        background: unset;

        button {
          border-radius: 0 1.5rem 1.5rem 0;
        }
      }
    }

    &._inRange {
      background: lightgray;

      button {
        background: lightgray;
        box-shadow: none;

        &:hover {
          background: lightgray;
        }
      }

      &:nth-child(7n) {
        background: linear-gradient(90deg, lightgray 50%, transparent 50%);

        button {
          background: lightgray;
          border-radius: 0;
        }
      }

      &:nth-child(7n + 1):not(:nth-child(1)) {
        background: linear-gradient(90deg, transparent 50%, lightgray 50%);

        button {
          background: lightgray;
          border-radius: 0 1.5rem 1.5rem 0;
        }
      }
    }

    @include for-phone-only {
      width: 43px
    }

    @include for-small-phone-only {
      width: 38px
    }
  }

  &__slot {
    border: 1px solid rgba(196, 205, 217, 0.54);
    border-radius: 50%;
    background: #F1F2F2;
    color: black;
    width: 100%;
    height: 100%;

    &._weekEnd {
      box-shadow: none;
      background: #FFEBEB;
      opacity: unset;
      color: #000514;
    }

    &:disabled {
      color: #A3A3A3;
    }
  }
}