.CircularProgressWithLabel {
  display: flex;
  justify-content: center;

  &__wrapper {
    position: relative;
    display: inline-flex;
  }

  &__label {
    inset: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__done {
    color: #5EC826;
  }

  &__progress {
    color: #FDD231;
  }

  &__start {
    color: #FF3B30;
  }
}