@import 'globalStyles';

.IssueShortStatus {
  .icon {
    width: 1rem;
    height: 1rem;
  }

  &._overdue .icon {
    fill: red;
  }

  &._closed .icon {
    width: 1rem;
    height: 1rem;
  }
}
