@import 'globalStyles';

.Tooltip {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  overflow: visible;
  cursor: default;

  &:hover {
    .Tooltip__dropdown {
      display: block;
    }
  }

  &._showByClick {
    cursor: pointer;

    &:hover {
      .Tooltip__dropdown {
        display: none;
      }
    }

    &._visible {
      .Tooltip__dropdown {
        display: block !important;
      }
    }
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    left: 50%;
    margin-top: 10px;
    margin-left: -1.5rem;
    background: #fff;
    padding: 1rem;
    border-radius: 3px;
    box-shadow: 0 2px 3px 3px rgba(0, 0, 0, 0.05);
    border: solid 1px #ccc;
    min-width: 10rem;
    display: none;
    z-index: $dropdownLayer;
  }

  &__arrow {
    width: 10px;
    height: 10px;
    position: absolute;
    background: #fff;
    top: -6px;
    left: 1rem;
    transform: rotate(-45deg);
    border: solid #ccc;
    border-width: 1px 1px 0 0;
  }
}