@import 'globalStyles';

.List {
  padding: 0 1rem;

  &__noResults {
    background: #fff;
    padding: 1rem;
    margin: 0 -1rem;
    text-align: center;
  }

  &__item {
    background: #fff;
    border-bottom: solid 1px $separatorColor;
    padding: 1rem;
    margin: 0 -1rem;
    position: relative;
    text-decoration: inherit;
    color: inherit;
    display: flex;
    align-items: stretch;

    &:last-child {
      border-bottom: none;
      margin-bottom: 16px;
    }

    &._disabled {
      @include disabled;
    }
  }

  &__sortHandler {
    border-right: solid 1px rgba(#000, 0.05);
    margin: -1rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: move;

    .icon {
      opacity: 0.3;
    }
  }

  &__itemContent {
    flex-grow: 1;
  }

  &__itemId {
    position: absolute;
    left: 1rem;
    top: 1rem;
    opacity: 0.5;
  }

  &._withId {
    .List__item {
      padding-left: 5rem;
    }
  }
}