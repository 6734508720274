@import 'globalStyles';

.Label {
  display: flex;
  background: rgba(#000, 0.1);
  border-radius: 3px;
  padding: 0 0.5rem;
  position: relative;
  font-size: 0.8rem;
  line-height: 1.5rem;
  overflow: hidden;
  height: 24px;

  &._removable {
    padding-left: 2rem;
  }

  .icon {
    @include clickable;
    position: absolute;
    left: 0rem;
    top: 50%;
    transform: translateY(-50%);
    vertical-align: middle;
    border-right: solid 1px #aaa;
    border-radius: 0;
    padding: 2px;
  }
}
