@import 'globalStyles';

.Modal {
  box-sizing: border-box;
  overflow: auto;
  cursor: auto;

  @include for-phone-only {
    top: 3.5em;
    background: #fff;
    padding: 0 10px;
  }

  &__title {
    padding: $modalPadding;
    text-transform: uppercase;
    display: flex;
    align-items: flex-start;

    @include for-phone-only {
      padding: $mobileModalPadding;
    }
  }

  &__content {
    padding: $modalPadding;


    @include for-phone-only {
      padding: $mobileModalPadding;
    }

    &._withTitle {
      padding-top: 0;
    }
  }

  &__close {
    margin-left: auto !important;
    padding: 0 !important;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;

    svg {
      fill: none;
      width: 24px;
      height: 24px;
    }

    &:hover {
      fill-opacity: 100%;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    text-align: right;
    width: 100%;
  }

  @include for-tablet-portrait-up() {
    &__container {
      min-height: 92vh;
    }
  }

  &__content, .content {
    overflow: initial;
    border-radius: 16px;
    height: auto;
  }

  @include for-phone-only() {
    .content {
      overflow-y: auto;
    }
  }

  &._confirm {
    @include for-phone-only {
      padding: 0 16px;
  }

    &.alarm {
      max-height: 300px;
      margin-top: auto;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &>.Modal__container {
      &>.content {
        border-radius: 10px;
        padding: 32px 28px 28px;
        box-shadow: none;
        
        width: auto;
        height: auto;
        min-width: 400px;

        @include for-phone-only {
          min-width: auto;
        }
      }
    }

    .Modal__content {
      padding: 0;
    }

    .Modal__title {
      text-transform: none;

      padding: 0;

      font-size: 17px;
      line-height: 22px;
      font-weight: 600;
    }

    .Modal__text {
      margin-top: 16px;

      font-size: 16px;
      line-height: 21px;
      font-family: 'robotoregular';
    }

    .Modal__close {
      display: none;
    }

    .Modal__buttons {
      text-align: center;
      gap: 12px;

      margin-top: 24px;

      ._ghost {
        background: rgba(0, 17, 51, 0.06);
        box-shadow: none;
      }

      button {
        margin: 0;
        min-width: 5rem;
        border-radius: 10px;

        font-size: 17px;
        line-height: 22px;

        padding: 13px 16px;

        @include for-phone-only {
          width: 100%;
        }
      }
    }
  }

  &.rateModal {
    .content {
      border-radius: 10px;
      max-width: 450px;
    }

    .Modal__title {
      display: none;
    }

    .ProcessingActionForm {
      margin-top: 24px;
    }

    @include for-phone-only {
      top: 0;
      background: none;

      .content {

      }

      .Modal__container {
        height: auto;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 16px;
      }
    }

    .FormButtons {
      margin-top: 24px;
      margin-bottom: 12px;
      width: 100%;
      text-align: end;

      button {
          font-size: 17px;
          line-height: 22px;
          padding: 13px 16px;

          border-radius: 10px;

          @include for-phone-only {
              width: 100%;
          };
      }
  }
    
  }

  &.captchaModal {
    .content {
      width: 470px;

      @include for-phone-only {
        width: 100%;
      }
    }

    .Modal__content {
      padding: 0 56px 48px;

      @include for-phone-only {
        padding: 0 24px 32px;
      }
    }

    .Modal__container {
      @include for-phone-only {
        padding: 0 16px;
      }
    }
    .Modal__title {
      padding: 16px 16px 0;
    }
  }
}
