@import 'reset.css';
@import "react-grid-layout/css/styles.css";
@import "react-resizable/css/styles.css";
@import 'lib/fonts/index';
@import 'lib/styles/typography';
@import 'lib/styles/globalStyles';
@import 'lib/styles/elements/index';

html {
  overflow-x: auto;
  background: $mainBg;
}

* {
  outline: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

body {
  font-family: 'robotomedium', sans-serif;
}

.app {
  @media (min-width: 1200px) {
    display: flex;
    align-items: stretch;
    width: 100vw;
    max-width: 100%;
    min-height: 100vh;
  }

  &._withUpdateBar {
    margin-top: 2.5rem;
  }

  &__storeLink {
    display: flex;
    justify-content: space-between;

    position: absolute;
    top: 0;
    left: 0;
    
    width: 100%;
    padding: 12px 10px 12px 16px;
    border-bottom: 1px solid #EBEBEB;

    text-decoration: none;
    color: #292929;
    background-color: white;

    & .wrapper {
      display: flex;
      align-items: center;
      gap: 12px;

      & .brand {
        width: 24px;
        height: 24px;
      }

      & .text {
        font-size: 16px;
        line-height: 21px;
        font-family: 'robotoregular';
      }
    }

    & .link_arrow {
      color: transparent;

      width: 24px;
      height: 24px;
    }
  }

}

.main {
  width: 100%;
  background: $mainBg;
  min-height: 100vh;
  box-sizing: border-box;
  height: 100vh;

  @media (max-width: 1199px) {
    padding-top: 117px;

    height: 100dvh;
    min-height: 100dvh;
  }

  &.miniAppMain {
    background-color: white !important;

    & .LocationLabel {
      margin: 0 auto;
    }
  }

}

.miniAppMain {
  @include for-tablet-portrait-up {
    padding-top: 3.125rem;
  }
}
