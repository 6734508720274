.ProfilePage {
  .Page__title {
    margin-bottom: 32px;
  }
  &__header {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  &__divider {
    max-width: 40rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
