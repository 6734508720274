@import './adaptivity';

.AsyncTreeView {
  box-sizing: border-box;
  overflow: auto;
  max-height: 35rem;

  @include for-phone-only {
    max-height: 25rem;
  }
}

.MuiTreeItem-content {
  border-bottom: 1px solid #E0E2E6;
}

.MuiTreeItem-group {
  margin: 0;
}
