.AnnouncementModal {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &__loader {
    position: fixed;
    left: 50%;
    top: 50%;
    &>svg {
      opacity: 0.4;
      stroke: #000;
      stroke-width: 4px;
      width: 4rem;
      height: 4rem;
    }
  }

  &__dates {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
