.SearchInput {
  height: 40px;
  display: flex;
  flex-flow: row;
  padding: 0 14px;
  border: 1px solid #eee;

  & > input {
    font-family: inherit;
    font-size: 1rem;
    line-height: 1.5rem;
    border: none;
    box-sizing: border-box;
    padding: 0;
    width: 100%;
  }

  &__icon-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 8px;

    & > svg {
      width: 20px;
      height: 20px;
      color: rgba(0, 0, 0, 0.54);
    }
  }
}
