@import 'globalStyles';

.Modal {
    &._newIssueSuccess {

        @include for-phone-only {
            padding: 0 16px;
        }

        &>.Modal__container {
            &>.content {
                height: auto;
                border-radius: 10px;
                box-shadow: none;
                max-width: 410px;

                &>.Modal__title {
                    display: none;
                }
                &>.Modal__content {
                    padding: 32px 28px 28px;
                }
            }
        }
    }

}

.NewIssueSuccess {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    &__icon {
        width: 40px;
        height: 40px;

        fill: none;
    }

    &__title {
        font-size: 17px;
        line-height: 22px;
        text-align: center;
    }

    &__subtitle {
        font-size: 15px;
        line-height: 20px;

        font-family: 'robotoregular';
        text-align: center;
    }

    &__button {
        width: 100%;
        border-radius: 10px;

        margin-top: 8px;

        font-size: 17px;
        line-height: 22px;
        padding: 13px 16px;
    }
}
