.SortableItem {
  &__wrapper {
    list-style: none;
    box-sizing: border-box;

    &._clone {
      display: inline-block;
      pointer-events: none;
      padding: 5px 0 0 10px;
      width: 100%;
      margin-left: var(--spacing);

      .SortableItem__node {
        margin-left: -10px;
        background: #FFFFFFE0;
        box-shadow: 0px 2px 8px 0px #88888866;
      }

      .SortableItem__icon-drag {
        opacity: 1;

        .icon {   
          fill: #212121;
        }
      }
    }

    &._root {
      margin-top: 8px;
    }

    &._ghost {
      opacity: 1;
      z-index: 1;
      
      .SortableItem__node > * {
        display: none;
      }
      
      .SortableItem__node {
        height: 12px;
        padding: 5px 0;
        background-color: transparent;
        
        .SortableItem__indicator {
          position: relative;
          display: block;
          height: 2px;
          width: 100%;
          background-color: #888888;

          &:before {
          position: absolute;
          z-index: 2;
          left: 0px;
          top: -3px;
          display: block;
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 50%;
          border: 2px solid #888888;
          background-color: #ffffff;
        }
        }
      }
    }

    &._disableInteraction {
      pointer-events: none;
    }
  }

  &__node {
    background: #fff;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px 12px;
    overflow: hidden;

    &:after {
      margin-left: var(--spacing);
      position: absolute;
      left: 0;
      bottom: 0;
      content: '';
      width: 100%;
      height: 1px;
      background: rgba(225, 226, 226, 1);
    }

    &._last {
      &:not(._expanded) {
        &:after {
          height: 0;
        }
      }
    }

    &._root {
      &:not(._expanded) {
        &:after {
          height: 0;
        }
      }
    }

    &:hover {
      background: rgba(249, 249, 249, 1);

      .SortableItem__actions {
        opacity: 1;
      }
    }

    &._projected {
      background-color: #F5F5F5;
      border: 1px dashed #888888;
    }
  }

  &__icon-drag {
    margin-left: var(--spacing);
    cursor: move;
    width: 24px;
    height: 24px;

    .icon {
      width: 24px;
      height: 24px;

      fill: #ccc;
    }

    &:hover {
      .icon {
        background: rgba(225, 226, 226, 0.4);
        border-radius: 4px;
      }
    }
  }

  &__icon-arrow,
  &__icon-folder {
    display: flex;
    align-items: center;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    cursor: pointer;
    margin-left: 10px;

    .icon {
      padding: 1px;
      fill: var(--color);
      width: 24px;
      height: 24px;
    }
  }

  &__icon-arrow {
    border: 1px solid rgba(225, 226, 226, 1);
    border-radius: 4px;

    .icon {
      transition: transform 250ms ease;
      transform: rotate(-90deg);
    }

    &._expanded .icon {
      transform: none;
    }
  }

  &__content {
    flex-grow: 1;
    padding-right: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 10px;
    display: flex;
    align-items: center;
  }

  &__actions {
    flex-shrink: 0;

    &._active {
      opacity: 1;

      .SortableItem__node {
        background: rgba(249, 249, 249, 1);
      }
    }
    opacity: 0;
    @media (max-width: 1200px) {
      opacity: 1;
    }
  }
}
