@import 'globalStyles';
@import 'elements/input';

.SelectInput {
     width: 100%;

     &__label {
          @include capitalize;
          color: $fieldNameColor;
          margin-bottom: 0.2rem;
          display: flex;
        }

     &__select {
          @include input;
          @include clickable;
          box-sizing: border-box;
          min-height: 2.5rem;
          padding: 2.3rem;
          display: flex;
          align-items: center;
          position: relative;
          flex-wrap: wrap;
          max-height: 72px;
          overflow: hidden;
          overflow-y: auto;
          margin-bottom: 16px;

          &._error {
            border-color: #FF3B30;
          }
      
          &:hover {
            background: #fafafa;
          }
     }

     &__header {
      display: flex;
      justify-content: space-between;
     }

     &__labelWrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
     }

     &__startIcon {
          margin-right: 8px;
          margin-left: -12px;
          opacity: 0.6;
     }

     &__arrow {
          position: absolute;
          right: 5px;
          transform: translateY(-50%);
          top: 50%;
      
          svg {
            fill: #000000;
          }
      
          &._expand {
              transform: translateY(-50%) rotate(-180deg);
          }
        }

        &__placeholder {
          color:  #CCC;
        }

        &__title {
          display: flex;
        }

        &__requiredMark {
          font-size: 0.8rem;
          flex-shrink: 0;
          color: #FF3B30;
          margin-left: 4px;
        }

        &__clear {
          position: absolute;
          right: 8px;
          opacity: 0.6;
        }
}