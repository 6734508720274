@import 'globalStyles';
@import 'styles/elements/input';

.TreeViewSelect {
  position: relative;

  &__clear {
    position: absolute;
    right: 0;
    top: -28px;
  }

  &__wrapper {
    position: relative;
  }

  &__label {
    color: #757575;
    margin-bottom: 0.2rem;
  }

  &__checkbox {
    margin-right: 0;
  }

  &__title {
    display: flex;
  }

  &__requiredMark {
    font-size: 0.8rem;
    flex-shrink: 0;
    color: #FF3B30;
    margin-left: 4px;
  }

  &__expand {
    position: absolute;
    pointer-events: none;
    right: 0.5rem;
    transform: translateY(-50%);
    top: 50%;

    .TreeViewSelect._expanded & {
      transform: translateY(-50%) rotate(-180deg);
    }
  }

  &__placeholder {
    opacity: 0.3;
  }

  &__value {
    @include input;
    @include clickable;
    box-sizing: border-box;
    min-height: 2.5rem;
    padding-right: 2.3rem;
    display: flex;
    align-items: center;
    position: relative;

    &._error {
      border-color:#FF3B30;
    }


    &:hover {
      background: #fafafa;
    }
  }

  &__start-icon {
    margin-right: 12px;
    margin-left: -6px;

  }

  &__valuesWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
  }

  &__dropdown {
    background: #fff;
    box-sizing: border-box;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 8px;
    overflow: hidden;
    border: solid 1px $separatorColor;
    border-radius: 2px;
    z-index: $dropdownLayer;
    box-shadow: 0 2px 3px 3px rgba(0, 0, 0, 0.1);
  }

  &__dropdownApply {
    text-align: right;
    padding: 0.5rem 1rem;
  }

  .TreeViewSelect__filter {
    width: 100%;
    overflow: hidden;

    & > div {
      display: flex;
    }

    .Search {
      flex-grow: 1;
    }

    .Button {
      border: none;
      box-shadow: none;
      border-left: solid 1px #ccc;
      border-radius: 0;
      -webkit-backface-visibility: hidden;
      -webkit-transform: translateZ(0) scale(1.0, 1.0);

      &:hover {
        background: transparent;
      }
    }

    input {
      width: 100%;
      border: 0 !important;
    }
  }

  .TreeViewSelect__filterExtra {
    width: 100%;
    flex-grow: 1;
    border-top: solid 1px #3878FF;
  }

  &__selectAll {
    padding: 0.5rem;

    .Checkbox {
      display: block;
      margin-right: 0.6rem;
      margin-top: 0.2rem;
      align-self: center;
    }
  }

  &__selectAllText {
    flex-grow: 1;
    margin-left: 1.5rem;
  }

  .TreeViewSelect {
    &__block{
      display: flex;
      align-items: center;
      min-height: 1.5rem;

      .Checkbox {
        padding: 0.3rem;
      }
    }
    &__title {
      flex-grow: 1;
      margin-right: 0.5rem;
      font-size: 15px;
    }
  }

  .TreeView {
    border: none;
    overflow: auto;
    max-height: 22rem;

    &__node._selected {
      background: inherit;
    }

    &__node-title {
      background: inherit !important;
      display: flex;
      align-items: center;
      height: 44px;
      padding: 10px 12px;
    }

    &__node-children {
      padding: 0;
    }

    &__dropAfter {
      display: none;
    }
  }

  .Loader {
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    bottom: 0.5rem;
    margin-top: 0.1rem;
    pointer-events: none;
    right: 0.5rem;
    stroke: rgba(0,0,0,0.3);
    stroke-width: 8px;
  }

  ._multiple .TreeView .Loader {
    right: 3.5rem;
  }
}
