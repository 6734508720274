.Tag {
  height: 24px;
  border-radius: 4px;
  background-color: #e1e2e2;
  display: inline-flex;
  flex-flow: row;
  align-items: center;

  &__close {
    box-sizing: border-box;
    padding: 4px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon-container {
    height: 16px;
    width: 16px;

    & > svg {
      height: 100%;
      width: 100%;
    }
  }

  &__separator {
    height: 100%;
    width: 1px;
    background-color: #888;
  }

  &__label {
    padding: 0 8px;
    font-weight: 500;
    line-height: 16px;
    font-size: 13px;
  }
}
