@import 'globalStyles';

.InOutCheckin {
  width: 100%;
  height: 100vh;
  background-color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Commissioner, sans-serif;

  &__content {
    text-align: center;
  }

  &__icon {
    width: 256px !important;
    height: 256px !important;
    fill: none !important;

    @include for-phone-only {
      width: 204px !important;
      height: 204px !important;
    }
  }

  &__title {
    color: #121E2D;
    font-size: 24px;
    line-height: 36px;
    font-weight: 500;

    margin-top: 16px;

    @include for-phone-only {
      font-size: 22px;
      line-height: 32px;
    }
  }

  &__subtitle {
    color: #20334E;
    font-size: 17px;
    line-height: 26px;

    margin-top: 32px;

    @include for-phone-only {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
