@import 'globalStyles';

@mixin input {
  font-family: inherit;
  outline: none;
  box-sizing: border-box;
  padding: $unit;

  background: $inputBgColor;
  border: solid 1px $separatorColor;
  border-radius: 2px;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.5rem 1rem !important;

  @include placeholder {
    opacity: 0.6;
  }
}

input {
  &[type=text],
  &[type=tel],
  &[type=number],
  &[type=email],
  &[type=password] {
    @include input;
  }
}

textarea {
  @include input;
  width: 100%;
  max-height: 300px;
  max-width: 100%;
  min-width: 100%;
  height: 5rem;
  min-height: 5rem;
}