@import 'globalStyles';

.TextFieldButton {
  &__label {
    @include capitalize;
    color: $fieldNameColor;
    margin-bottom: 0.2rem;
    display: flex;
    margin-right: 5px;
  }

  &__button {
    height: 42px;
    color: #000000;
    background: #fff;
    border: solid 1px #ccc;
    width: 100%;
    justify-content: flex-start;
    font-size: 1rem;

    &._error{
      border-color:#FF3B30;
    }
  }

  &__placeholder {
    color:  #CCC;
  }

  &__icon {
    margin-right: 10px;
    margin-left: -12px;
    opacity: 0.6;
  }

  &__title {
    display: flex;
  }

  &__requiredMark {
    font-size: 0.8rem;
    flex-shrink: 0;
    color: #FF3B30;
    margin-left: 4px;
  }

  &__placeholder {
    color:  #CCC;
  }

  &__clear {
    position: absolute;
    right: 8px;
    opacity: 0.6;
  }
}
