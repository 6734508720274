.TimePicker {
     width: 100%;
     margin-bottom: 16px;
     
     &__timeSelect {
          width: 328px;
          padding: 24px 24px 16px;
     }

     &__title {
          font-size: 10px;
          font-weight: 500;
          text-transform: uppercase;
          color: #717370;
          font-family: 'robotomedium';
          margin-bottom: 24px;
     }
}