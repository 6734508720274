.ConfirmModal {

  &__buttons {
    display: flex;
    gap: 10px;
    margin-top: 1.5rem;
  }

  &__button {
    &._error {
      background: #FF3B30 !important;
      color: white;
    }

  }
}
