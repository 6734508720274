@import "globalStyles";

.ActivitiesSettingsDialog {
  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    -moz-column-gap: 16px;
         column-gap: 16px;
  }
  &__input {
    &._full {
      grid-column: 1/3;
    }
  }

  .FormButtons {
    margin-top: 1rem;
  }

  &__horizontal-select {
    .SimpleSelect {
      &__dropdown {
        margin-top: 0.5rem;
        width: 100%;
      }

      &__options div {
        display: flex;
        flex-wrap: wrap;
        max-height: 15rem;
      }

      &__option {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        padding: 10px;
      }

      &__value {
        .SimpleSelect__icon {
          width: 1.5rem;
          height: 1.5rem;
        }
      }

      &__icon {
        margin-right: 0;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        background: $textColor;
      }
    }
  }

  &__link-fields {
    grid-column: 1/3;

    display: flex;
    flex-direction: column;

    gap: 16px;
  }

}
