@import 'globalStyles';

.SideBanner {
  padding: 16px 32px;
  background: var(--tertiary-200, #F3F7FF);
  grid-area: banner;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__caption {
    color: var(--secondary-100, #39506C);
    font-family: IBM Plex Sans, serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.195px;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  &__header {
    font-family: Commissioner, serif;
    color: var(--secondary-400, #121E2D);
    font-weight: 600;
    font-size: 20px;
    white-space: pre-line;
    margin-bottom: 40px;
  }

  &__icon {
    & svg {
      fill: transparent;
      width: 288px;
      height: 32px;
      margin-bottom: 24px;
    }
  }

  &__functional_item {
    color: var(--secondary-100, #39506C);
    font-family: IBM Plex Sans, serif;
    font-size: 13px;

    &:not(:last-child):after {
      content:"\00B7";
      margin: 8px;
    }
  }

  &__footer {
    position: absolute;
    bottom: 32px;
    width: calc(400px - 64px);
  }

  &__footerText {
    display: flex;
    gap: 12px;
    font-family: IBM Plex Sans, serif;
    font-size: 13px;
    padding: 12px 0;
    border-bottom: 1px solid #D5E0FF;
    margin-bottom: 12px;
  }

  .Logo {
    margin-bottom: 104px;

    & svg {
      background-color: transparent;
    }
  }

  @include for-tablets-and-phones {
    display: none;
  }
}

