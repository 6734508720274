.BookingItemsList {
  margin-top: 20px;

  &__location {
    font-size: 12px;
    color: gray;
  }

  &__filters {
    margin-top: 24px;
  }

  &__time-interval {
    display: flex;
    gap: 16px;
    max-width: 400px;

    margin-bottom: 16px;

    &>.FormField, .TimePicker {
      margin-bottom: 0;
    }
  }

  &__pagination {
    margin-top: 15px;
  }
}