@import 'globalStyles';

.ActivitiesLocationSelect {
  &._readonly {
    @include for-phone-only {
      text-align: center;
      margin: 0 -1rem;
      margin-bottom: -1rem;
    }
  }

  .LocationLabel {
    font-size: 1rem;
    margin-left: -0.5rem;

    @include for-phone-only {
      margin-left: 0;
    }
  }
}