@import 'globalStyles';

@mixin Field {
  width: 100%;
  position: relative;
  margin-bottom: 1rem;

  &__label {
    @include capitalize;
    color: $fieldNameColor;
    margin-bottom: 0.2rem;
    display: flex;
    justify-content: start;
    align-items: start;
  }
}

.FormField {
  @include Field;

  margin-bottom: 16px;

  &.search {
    margin-top: 24px;
  }

  &__input {
    position: relative;

    textarea, .input_text {
      width: 100%;
      margin: 0;
    }
  }

  &__icon, &__clear, &__adornment {
    position: absolute !important;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
  }

  &__adornment {
    fill: transparent;
  }

  &__icon, &__clear {
    @include clickable;
    opacity: 0.5;
  }

  &__labelText {
    flex-grow: 1;
  }

  &__boldText {
    font-weight: 600;
  }

  &__requiredMark {
    font-size: 0.8rem;
    flex-shrink: 0;
    color: #FF3B30;
    margin-left: 4px;
  }

  &__hint {
    font-size: 0.8rem;
    opacity: 0.8;

    &._right {
      justify-content: flex-end;
      display: flex;
    }
  }

  &__error {
    font-size: 12px;
    line-height: 16px;
    color: #FF3E38;
    justify-content: flex-end;
    display: flex;
  }

  &__loginError {
    margin-top: 6px;
    font-size: 12px;
    line-height: 16px;
    color: #FF3E38;
    justify-content: flex-start;
    display: flex;
  }

  &._disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__clear {
    background: #fff;
    opacity: 1;

    ._Select & , ._TreeViewSelect & {
      z-index: 2;
    }

    ._Textarea & {
      top: 20px;
    }
  }

  &._invalid._touched, &._invalid  {
    margin: -0.5rem;
    margin-bottom: 0.5rem;
    padding: 0.5rem;

    box-sizing: initial;

    & .FormField__input {
      border: 1px solid #FF3B30;

      & div {
        border: none !important;
        outline: none;
      }
      & input {
        border: none !important;
        outline: none;
      }
    }
  }

  &__tooltip {
    margin-left: 5px;

    &>.Tooltip__dropdown {
      width: 300px;
    }
  }

  &__tooltip-inline {
    margin-left: -10px;
    vertical-align: sub !important;

    &>.Tooltip__dropdown {
      width: 300px;
    }
  }

  &._CurrencySelect {
    width: 8rem;
  }
}

.FormButtons {
  margin-top: 2rem;

  button:not(:last-child) {
    margin-right: 8px;
  }

  &._right {
    display: flex;
    justify-content: flex-end;
  }

  &._confirm-code {
    margin-top: 16px;

    .ghost {
      background-color: #e7e9eb;
    }

    & button {
      width: 49%;
      border: none;
      border-radius: 4px;
    }
  }
}
