@import 'globalStyles';

.LocationFullPath {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &__part {
    white-space: nowrap;
    display: inline-block;
  }

  &__info-icon {
    margin-left: auto !important;
    padding: 0.125rem;

    a {
      text-decoration: none;
      color: black;
    }
  }

  p {
    display: inline;
  }
}
