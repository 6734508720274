@import 'globalStyles';

.Activity {
  @include clickable;
  $iconSize: 6rem;

  position: relative;
  box-sizing: border-box;

  overflow: hidden;
  margin: -1px;
  width: 33.3333%;
  max-width: 250px;
  min-height: 12.5rem;
  color: black;

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
    /* initial ratio of 1:1*/
  }

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 24px;
  }

  &:hover {
    background: rgba(255,255,255,0.5);
  }

  &__icon {
    width: $iconSize;
    height: $iconSize;
    border-radius: $iconSize*2;
    padding: 0.5rem;

    box-sizing: border-box;
  }

  &__name {
    margin-top: 12px;
    text-align: center;
    width: 80%;
  }

  @include for-phone-only {
    $iconSize: 12vh;
    width: 50%;
    height: 174px;

    &__icon {
      width: 80px;
      height: 80px;
    }

    &__name {
      line-height: 20px;
      font-size: 14px;
      font-family: 'robotoregular';
    }
  }
}
